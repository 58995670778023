import { Field } from "./Field";
import { Category, ItemFieldMask } from "./Category";
import { ILabel, TodoTypes } from "../client";
import { FieldHandlerFactory } from "../../core/common/businesslogic/FieldHandlers/index";
import { IFieldHandler } from "../../core/common/businesslogic/FieldHandlers/IFieldHandler";
import { IItemGet, IItemHistory, IItemPut, IReference } from "../../core/globals";
import { GetTodosAck } from "../rest-api";
import { DocItem } from "./DocItem";

export { Item };

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function assert(result: boolean, msg?: string) {
    if (!result) {
        throw new Error("assertion failed: " + msg ? msg : "<unknown error>");
    }
}

/**
 * An Item represents a database item. Every Item must have at least a category.
 * If it has an id, then it was retrieved from the database and may be altered and later
 * saved (use needsSave() to determine if the Item needs saving). If it doesn't have
 * an id, it needs to be saved. When an item is saved, it's data is re-initialized from
 * the database.
 */
class Item {
    /**
     * Construct an Item
     * @param category
     * @param item
     * @param fieldMask
     */
    constructor(
        private category: Category,
        item?: IItemGet,
        fieldMask?: ItemFieldMask,
    ) {
        this.dirty = false;
        this.fieldMap = new Map<number, Field>();
        this.isFolderProperty = category.isFolderCategory();
        if (this.isFolderProperty) {
            if (!item) {
                throw new Error(`A folder requires an item`);
            }
            if (!item.type) {
                throw new Error(`A folder requires item.type`);
            }
        }
        if (fieldMask) {
            this.fieldMask = fieldMask;
        } else {
            // Create a field mask that includes all fields.
            this.fieldMask = this.category.createFieldMask();
        }
        this.setData(item);
    }

    private fieldMask: ItemFieldMask;
    private fieldMap: Map<number, Field>;
    private dirty: boolean;
    private id: string | undefined;
    private type: string | undefined;
    private title: string | undefined;
    private labels: string[] | undefined;
    private isFolderProperty: boolean;
    private downLinks: IReference[] | undefined;
    private upLinks: IReference[] | undefined;
    private creationDate: string | undefined;
    private maxVersion: number | undefined;
    private history: IItemHistory[] | undefined;

    // TODO: get the rest of the fields from IItem and eliminate member variable {toBeIntegrated}.
    protected toBeIntegrated: IItemGet = {};
    // TODO: also, what about labels? Are these in toBeIntegrated right now?

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setDirty() {
        this.dirty = true;
    }

    /**
     * Gets the ItemFieldMask which specifies which fields are loaded
     * @returns on ItemFieldMask object
     */
    getFieldMask(): ItemFieldMask {
        return this.fieldMask;
    }

    /**
     * Read-only.
     * @returns The highest version reached for this item, or undefined if the
     *     item hasn't yet been saved on the server.
     */
    getMaxVersion(): number | undefined {
        return this.maxVersion;
    }

    /**
     * Return the history for an item, if present.
     * @returns an IItemHistory array
     */
    getHistory(): IItemHistory[] {
        if (this.history) {
            return this.history;
        }
        return [];
    }

    private hasLink(array: IReference[] | undefined, id: string): boolean {
        if (array) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return array.filter((l) => l.to == id).length > 0;
        }
        return false;
    }

    private addLink(array: IReference[] | undefined, id: string, title?: string): IReference[] {
        let newRef: IReference = { to: id, title: title || "" };
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (array == undefined) {
            array = [];
        }
        assert(!this.hasLink(array, id));
        array.push(newRef);
        return array;
    }

    private removeLink(array: IReference[], id: string): IReference[] {
        assert(this.hasLink(array, id));
        for (let i = 0; i < array.length; i++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (array[i].to == id) {
                array.splice(i, 1);
                break;
            }
        }
        return array;
    }

    /**
     * Return any downlinks.
     * @returns An array of downlinks (may be undefined)
     */
    getDownlinks(): IReference[] {
        return this.downLinks ? this.downLinks : [];
    }

    /**
     * Check if there is a downlink to another item
     * @param id
     * @returns true if this item links to the item given by {id}
     */
    hasDownlink(id: string): boolean {
        return this.hasLink(this.downLinks, id);
    }

    /**
     * Replace the current array of downlinks with a new one.
     * @param downLinks
     * @returns the Item itself
     */
    setDownlinks(downLinks: IReference[]): Item {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (downLinks.length == 0) {
            this.downLinks = undefined;
        } else {
            this.downLinks = downLinks;
        }
        this.setDirty();
        return this;
    }

    /**
     * Add a new downlink to the item. Does nothing if the item is
     * already represented
     * @param id - the id of the item to add.
     * @param title - optional. The title is just for convenience.
     *     It is neither saved nor representative of the actual title of the item.
     * @throws Error if the passed id matches the id of the current item.
     * @returns the Item
     */
    addDownlink(id: string, title?: string): Item {
        // prevent self-own
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (id == this.id) {
            throw new Error(`An Item may not refer to itself`);
        }
        if (!this.hasLink(this.downLinks, id)) {
            this.downLinks = this.addLink(this.downLinks, id, title);
            this.setDirty();
        }
        return this;
    }

    /**
     * Remove a link given by {id} if it exists.
     * @param id  the id of the downlinked item to remove
     * @returns the current Item
     */
    removeDownlink(id: string): Item {
        if (this.hasLink(this.downLinks, id)) {
            this.downLinks = this.removeLink(this.downLinks || [], id);
            this.setDirty();
        }
        return this;
    }

    /**
     * Return any uplinks.
     * @returns An array of uplinks (may be undefined)
     */
    getUplinks(): IReference[] {
        return this.upLinks ? this.upLinks : [];
    }

    /**
     * Check if there is an uplink to another item
     * @param id
     * @returns true if this item links to the item given by {id}
     */
    hasUplink(id: string): boolean {
        return this.hasLink(this.upLinks, id);
    }

    /**
     * Replace the current array of uplinks with a new one.
     * @param upLinks
     * @returns the Item itself
     */
    setUplinks(upLinks: IReference[]): Item {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (upLinks.length == 0) {
            this.upLinks = undefined;
        } else {
            this.upLinks = upLinks;
        }
        this.setDirty();
        return this;
    }

    /**
     * Add a new uplink to the item. Does nothing if the item is
     * already represented
     * @param id - the id of the item to add.
     * @param title - optional. The title is just for convenience.
     *     It is neither saved nor representative of the actual title of the item.
     * @throws Error if the passed id matches the id of the current item.
     * @returns the Item
     */
    addUplink(id: string, title?: string): Item {
        // prevent self-own
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (id == this.id) {
            throw new Error(`An Item may not refer to itself`);
        }
        if (!this.hasLink(this.upLinks, id)) {
            this.upLinks = this.addLink(this.upLinks, id, title);
            this.setDirty();
        }
        return this;
    }

    /**
     * Remove a link given by {id} if it exists.
     * @param id  the id of the uplinked item to remove
     * @returns the current Item
     */
    removeUplink(id: string): Item {
        if (this.hasLink(this.upLinks, id)) {
            this.upLinks = this.removeLink(this.upLinks || [], id);
            this.setDirty();
        }
        return this;
    }

    /**
     * Helper method to test if a field id is valid within the Item Category, irrespective of
     * whether or not it is specified in the mask.
     * @param fieldId
     * @returns true if fieldId is valid within the Category.
     */
    private isValidFieldId(fieldId: number): boolean {
        return (
            this.getCategory()
                .getFields()
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                .filter((c) => c.id == fieldId).length > 0
        );
    }

    /**
     * Initializes the data fields for the item from an IItemGet structure
     * @param item
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private setData(item?: IItemGet) {
        this.dirty = false;

        // toBeIntegrated contains standard item fields I just haven't gotten around to
        // exposing and validating yet.

        if (item) {
            this.toBeIntegrated = item;
            this.id = item.id;
            this.type = item.type;
            this.title = item.title;
            this.labels = item.labels;
            this.isFolderProperty = !!item.isFolder;
            this.downLinks = item.downLinks;
            this.upLinks = item.upLinks;
            this.maxVersion = item.maxVersion;
            this.history = item.history;
            if (item["creationDate"]) {
                this.creationDate = item["creationDate"];
            }
            assert(
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                this.type == this.category.getId() || (this.isFolderProperty && this.category.isFolderCategory()),
                `the item type ${this.type} does not match category type ${this.category.getId()}`,
            );
        } else {
            // We can assert that we don't have a folder, because the constructor, which
            // calls this method doesn't allow an undefined item parameter for folders.
            assert(!this.category.isFolderCategory(), "A folder requires a valid item parameter");
            this.toBeIntegrated = {};
            // This is a new item.
            this.id = undefined;
            // Category
            this.type = this.category.getId();
            this.title = undefined;
            this.isFolderProperty = false;
            this.history = [];
        }

        // Now deal with the category fields.
        for (let field of this.category.getFields()) {
            // The mask influences whether we actually have this field data or not.
            if (this.fieldMask.hasFieldId(field.id)) {
                let value: string | undefined = undefined;
                if (item && item[field.id]) {
                    value = item[field.id];
                }

                FieldHandlerFactory.UpdateFieldConfig(
                    this.category.getItemConfig(),
                    this.category.getTestConfig(),
                    field.fieldType,
                    this.type || "",
                    value || "",
                    field.parameterJson || {},
                );
                let handler: IFieldHandler = FieldHandlerFactory.CreateHandler(
                    this.category.getItemConfig(),
                    field.fieldType,
                    field.parameterJson || {},
                );
                handler.initData(value);

                this.fieldMap.set(field.id, new Field(this, field, handler));
            }
        }

        // TODO: deal with labels. They show up as a pseudo field, but are really not.
    }

    /**
     * Sometimes you've been given an Item with a restrictive ItemFieldMask, however, you'd
     * like to set a value for a field that was not in the mask. With this method, you can
     * expand the field mask to include the field given by the fieldId (easily obtained
     * from the Category object).
     *
     * This field will be added to the mask, and the associated Field object will be returned
     * with an empty value, which you could set. The object will be marked as "dirty" at this point,
     * because we don't know if the server has an empty value for this field or not, so we assume
     * the pessimistic case.
     * @param fieldId a valid fieldId from the Category of the item.
     * @throws if the fieldId is already in the ItemFieldMask, or if the fieldId is not valid for the Category.
     * @returns the Field object
     */
    expandFieldMaskWithEmptyField(fieldId: number): Field | undefined {
        if (this.fieldMask.hasFieldId(fieldId)) {
            throw new Error(`Field ${fieldId} is already specified in the field mask`);
        }

        const foundFields = this.getCategory()
            .getFields()
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            .filter((c) => c.id == fieldId);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (foundFields.length == 0) {
            throw new Error(`Field ${fieldId} does not exist in category ${this.getCategory().getId()}`);
        }

        // Update the mask.
        let fieldIds = this.fieldMask.getFieldIds();
        fieldIds.push(fieldId);
        this.fieldMask = this.category.createFieldMask(fieldIds);

        // Create the Field.
        const field = foundFields[0];
        const value: undefined = undefined; // empty field.
        FieldHandlerFactory.UpdateFieldConfig(
            this.category.getItemConfig(),
            this.category.getTestConfig(),
            field.fieldType,
            this.type || "",
            "",
            field.parameterJson || {},
        );
        let handler: IFieldHandler = FieldHandlerFactory.CreateHandler(
            this.category.getItemConfig(),
            field.fieldType,
            field.parameterJson || {},
        );
        handler.initData(value);

        this.fieldMap.set(field.id, new Field(this, field, handler));
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        assert(field.id == fieldId);

        this.setDirty();

        return this.getFieldById(field.id);
    }

    /**
     * Export the data from this item into an IItemPut structure
     * @returns An IItemPut structure, filled in from the current state of the Item.
     */
    extractData(): IItemPut {
        let item: IItemPut = {
            upLinks: this.upLinks,
            upLinkList: this.toBeIntegrated.upLinkList,
            downLinks: this.downLinks,
            children: this.toBeIntegrated.children,
            history: this.history,
            modDate: this.toBeIntegrated.modDate,
            isUnselected: this.toBeIntegrated.isUnselected,
            availableFormats: this.toBeIntegrated.availableFormats,
            selectSubTree: this.toBeIntegrated.selectSubTree,
            requireSubTree: this.toBeIntegrated.requireSubTree,
            icon: this.toBeIntegrated.icon,
            type: this.type,
            id: this.toBeIntegrated.id,
            title: this.title,
            linksUp: this.upLinks ? this.upLinks.map((u) => u.to).join(",") : "",
            linksDown: this.downLinks ? this.downLinks.map((u) => u.to).join(",") : "",
            isFolder: this.isFolderProperty,
            isDeleted: this.toBeIntegrated.isDeleted,
            maxVersion: this.maxVersion,
            docHasPackage: this.toBeIntegrated.docHasPackage,
            // TODO: where is parent in IItemGet? Doesn't every item have a parent?
            labels: this.labels ? this.labels.join(",") : "",
            // We store in "label" the same thing as "labels" because servers before 2.4.2 expect "label"
            // This is just a kindness to keep different versions of the SDK broadly compatible with the
            // 2.4.x server/client.
            label: this.labels ? this.labels.join(",") : "",
            // TODO: where is crossLinks in IItemPut?
        };

        // Now deal with the category fields.
        for (let field of this.category.getFields()) {
            // The mask influences what we send out.
            if (this.fieldMask.hasFieldId(field.id)) {
                const myField = this.fieldMap.get(field.id);
                item[field.id] = myField?.getHandlerRaw().getData();
                // TODO: do we need to do this?
                item[`fx${field.id.toString()}`] = myField?.getHandlerRaw().getData();
            }
        }

        // Note that "creationDate" was ignored.
        return item;
    }

    /**
     * Get the unique Id of the Item within the Project.
     * @returns a string value containing the Item Id.
     */
    getId(): string {
        return this.id || "";
    }

    /**
     * isFolder returns true if the Item is of Category FOLDER.
     * @returns true if a FOLDER, false otherwise
     */
    isFolder(): boolean {
        return this.isFolderProperty;
    }

    /**
     * Returns the type (Category) of the Item.
     * @returns the Item type
     */
    getType(): string {
        assert(
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            this.type == this.category.getId() || (this.isFolder() && this.category.isFolderCategory()),
            `Item type ${this.type} does not match category type ${this.category.getId()}`,
        );
        return this.type || "";
    }

    /**
     * Get the creation date of the item
     * @returns a string date value or undefined
     */
    getCreationDate(): string | undefined {
        return this.creationDate;
    }

    /**
     * Set the creation date.
     * @param creationDate
     * @returns the Item itself.
     */
    setCreationDate(creationDate: string): Item {
        this.creationDate = creationDate;
        // Not setting this as dirty because creationDate doesn't "really" change.
        // It is just an expensive piece of information from the server that needs a
        // home.
        return this;
    }

    /**
     * Get the Item title
     * @returns the Item title
     */
    getTitle(): string {
        return this.title || "";
    }

    /**
     * Set the title of the Item
     * @param title
     * @returns the Item itself
     */
    setTitle(title: string): Item {
        if (title !== this.title) {
            this.title = title;
            this.setDirty();
        }
        return this;
    }

    /**
     * Returns an array of labels set for the Item.
     * @returns array of strings
     */
    getLabels(): string[] {
        return this.labels ? this.labels : [];
    }

    private labelsToString(): string {
        if (this.labels) {
            return this.labels.join(",");
        }
        return "";
    }

    private stringToLabels(input: string): string[] {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (input == undefined || input == "") {
            return [];
        }
        return input.split(",");
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private verifyLabelsAllowed(labels: string[]) {
        // Are labels allowed for our category?
        const labelDefs: ILabel[] = this.category
            .getProject()
            .getLabelManager()
            .getLabelDefinitions([this.type || ""]);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (labelDefs.length == 0 && labels.length > 0) {
            // Forgive empty strings.
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            const foundNonEmpty = labels.some((l) => l != undefined && l != "");
            if (foundNonEmpty) {
                throw new Error(`Category ${this.type} doesn't allow labels`);
            }
        }
    }

    /**
     * Sets the labels for an Item, overwriting any previous labels
     * @param newLabels
     * @returns Item
     * @throws throws error if the Item category doesn't allow labels
     */
    setLabels(newLabels: string[]): Item {
        this.verifyLabelsAllowed(newLabels);
        const oldlabels = [...this.getLabels()];
        const labelManager = this.category.getProject().getLabelManager();
        this.labels = this.stringToLabels(labelManager.setLabels("", newLabels));
        if (labelManager.compareLabels(oldlabels, this.labels).changed) {
            this.setDirty();
        }
        return this;
    }

    /**
     * Adds one label to the item if it isn't already set. Note that if the
     * label is in an XOR group with another set label, that label will be
     * removed.
     * @param labelToSet
     * @returns Item
     * @throws throws error if the Item category doesn't allow labels
     */
    setLabel(labelToSet: string): Item {
        this.verifyLabelsAllowed([labelToSet]);
        const labelsAsString = this.labelsToString();
        const oldlabels = [...this.getLabels()];
        const labelManager = this.category.getProject().getLabelManager();
        this.labels = this.stringToLabels(labelManager.setLabels(labelsAsString, [labelToSet]));
        if (labelManager.compareLabels(oldlabels, this.labels).changed) {
            this.setDirty();
        }
        return this;
    }

    /**
     * Unsets one label if it exists.
     * @param labelToUnset
     * @returns Item
     * @throws throws error if the Item category doesn't allow labels
     */
    unsetLabel(labelToUnset: string): Item {
        this.verifyLabelsAllowed([labelToUnset]);
        const oldlabels = [...this.getLabels()];
        const labelManager = this.category.getProject().getLabelManager();
        this.labels = labelManager.unsetLabel(this.getLabels(), labelToUnset);
        if (labelManager.compareLabels(oldlabels, this.labels).changed) {
            this.setDirty();
        }
        return this;
    }

    /**
     * Return the Category for the current item
     * @returns Category
     */
    getCategory(): Category {
        return this.category;
    }

    /**
     * needsSave() checks the Fields of the Category to which the Item belongs to see if they've
     * been changed. If so it marks the Item as dirty.
     * @returns true if the Item has changes that should be propped to the server
     */
    needsSave(): boolean {
        // Are any fields dirty?
        for (let field of this.fieldMap.values()) {
            if (field.needsSave()) {
                this.dirty = true;
                break;
            }
        }
        return this.dirty;
    }

    /**
     * An Item can be complete or partial, based on the ItemFieldMask passed in
     * at construction.
     * @returns true if the item has all of its Category fields.
     */
    hasAllFields(): boolean {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.fieldMask.getFieldIds().length == this.getCategory().getFields().length;
    }

    /**
     * In case the Item is masked (hasAllFields() returns false), one or more Fields may
     * not be tracked. hasFieldId() allows you to check if the field is present.
     * @param fieldId a valid field id within the Category
     * @throws Error if fieldId is not valid within the Category
     * @returns true if the Item's mask allows for this field.
     */
    hasFieldId(fieldId: number): boolean {
        if (!this.isValidFieldId(fieldId)) {
            throw new Error(`Field id ${fieldId} is not valid within Category ${this.getCategory().getId()}`);
        }
        return this.fieldMask.hasFieldId(fieldId);
    }

    getFieldById(fieldId: number): Field | undefined {
        // Is it in our mask?
        if (!this.hasFieldId(fieldId)) {
            throw new Error(`Field id ${fieldId} is not in the ItemFieldMask for this Item.`);
        }

        // We should definitely have the field at this point.
        assert(this.fieldMap.has(fieldId));

        return this.fieldMap.get(fieldId);
    }

    /**
     * Returns all fields within the mask which match the fieldName.
     * @param fieldName
     * @returns an array of Fields. Note that if the mask has limited the set of fields from
     *     the Category which are tracked for this particular item, the number of returned Field
     *     objects may be less than you expect.
     */
    getFieldByName(fieldName: string): Field[] {
        let results: Field[] = [];
        for (let field of this.fieldMap.values()) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (field.getFieldName() == fieldName) {
                results.push(field);
            }
        }
        return results;
    }

    /**
     * Returns a Field matching the field name. The field should exist and be within the mask.
     * @param fieldName
     * @throws Error if there is no such field, either because the name is invalid or it is not within
     *     the mask for the Item.
     * @returns a valid Field.
     */
    getSingleFieldByName(fieldName: string): Field {
        const fields = this.getFieldByName(fieldName);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        assert(fields.length == 1, `There are ${fields.length} fields with name ${fieldName}`);
        return fields[0];
    }

    /**
     * Returns all fields within the mask which match the fieldType.
     * @param fieldType
     * @returns an array of Fields. Note that if the mask has limited the set of fields from
     *     the Category which are tracked for this particular item, the number of returned Field
     *     objects may be less than you expect.
     */
    getFieldsByType(fieldType: string): Field[] {
        let results: Field[] = [];
        for (let field of this.fieldMap.values()) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (field.getFieldType() == fieldType) {
                results.push(field);
            }
        }
        return results;
    }

    /**
     * Create a Todo attached to this item.
     * @param users an array of user names
     * @param type
     * @param text
     * @param atDate
     * @returns A comma-separated list of Todo ids (integers), relative to the Item.
     */
    async createTodo(users: string[], type: TodoTypes, text: string, atDate: Date): Promise<string> {
        return this.category.getProject().createTodo(users, type, text, this.id || "", null, atDate);
    }

    /**
     * Return the Todos associated with this item.
     * @param includeDone - if true, includes done todos
     * @param includeAllUsers - if true, includes all todos for all users.
     * @param includeFuture - false by default. If true, includes future todos.
     * @returns Information on the Todos
     */
    async getTodos(includeDone?: boolean, includeAllUsers?: boolean, includeFuture?: boolean): Promise<GetTodosAck> {
        return this.category.getProject().getTodos(this.id, includeDone, includeAllUsers, includeFuture);
    }

    /**
     * Visit the server and get this Item as a DocItem.
     * @throws Error if the fields of this Item are dirty.
     * @returns a DocItem.
     */
    async toDocItem(): Promise<DocItem> {
        const dirty = this.needsSave();
        if (dirty) {
            throw new Error(`This item needs to be saved first`);
        }
        const project = this.category.getProject();
        return project.getItemAsDoc(this.getId());
    }
}
