// Exposes a surface to be used by plugins.
import { AddFileAck, FolderAnswer, CopyItemAck, GetSettingAck, ExecuteParam, TrimAuditList } from "./rest-api";
import { Configuration } from "./configuration";

import {
    pluginHooks,
    IPlugin,
    IProjectPageParam,
    IPluginPanelOptions,
    plugins,
    ISearchResult,
    ISetField,
    TestManager,
    tableMath,
} from "../core/common/businesslogic/index";
import { IExternalItem, MR1, IItemChangeEvent, IWltItemWithLinks, Tasks } from "../core/common/businesslogic/index";
import { ml } from "../core/common/matrixlib";
import { IBaseControlOptions } from "../core/common/UI/Controls/BaseControl";
import { refLinkStyle, refLinkTooltip } from "../core/common/UI/Parts/RefLinkDefines";
import { RiskCalculator, RiskControlImpl } from "../core/common/UI/Controls/riskCtrl2";
import type {
    IRiskControlOptions,
    IRiskParameter,
    IRiskValue,
    IRiskValueFactor,
    IRiskValueFactorWeight,
    IRiskValueMitigation,
    IRiskValueMitigationChange,
    IRiskValueMap,
    IRiskRender,
} from "../core/common/UI/Controls/riskCtrl2";
import {
    XRGetProject_CategoryList_GetProjectStructAck,
    XRGetProject_Needle_TrimNeedle,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRGetProject_Todos_GetTodosAck,
    XRGetTodosAck,
    XRProjectInfo,
    XRTodo,
    XRTodoCount,
    XRTrimNeedle,
    XRTrimNeedleItem,
} from "../core/RestResult";
import { TodoTypes } from "./common/enums";
import { ProjectStorage } from "../core/client/ProjectStorage";
import { ItemConfiguration } from "../core/common/businesslogic/index";

import { IItemSelectionParams } from "../core/common/UI/Controls/itemSelection";
import { IDB, NotificationsBL } from "../core/common/businesslogic/index";
import { ILineEditorLine } from "../core/common/UI/ILineEditor";
import {
    IDropdownOption,
    ILabel,
    INotificationConfig,
    notificationSetting,
    defaultNotificationConfig,
    IFieldParameter,
} from "../core/ProjectSettings";
import { mTM } from "../core/common/businesslogic/index";
import { XRProjectType } from "../core/RestResult";

import { IContextInformation } from "../core/common/matrixlib/MatrixLibInterfaces";
import { IContextPageConfigTab } from "../core/ProjectSettings";

import { Notifications } from "../core/client/plugins/Notifications";
import { IGlobalPrintFunctionParams, IPrintFunction } from "../core/printinterface/PrintFunction";
import { IProcessResult, IPrintGlobals } from "../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../print/src/PrintProcessor";
import { printProcessorRegistry } from "../print/src/PrintProcessorRegistry";
import { IPrintFieldParams } from "../print/src/PrintValueOf";
import { ControlCore } from "./plugins/ControlCore";
import { PluginCore } from "./plugins/PluginCore";
import * as PluginInterfaces from "./plugins/interfaces";

import { ConfigPage } from "../core/admin/lib/ConfigPage";
import { LineEditor } from "../core/admin/lib/LinEditor";

import {
    IStringMap,
    IItem,
    IRestResult,
    IStringNumberMap,
    IReference,
    IAnyMap,
    globalMatrix,
    matrixSession,
    restConnection,
    app,
    ControlState,
    setIC,
    matrixApplicationUI,
    IItemGet,
    IDataStorage,
} from "../core/globals";
import { SimpleItemTools } from "../core/common/matrixlib/SimpleItemTools";
import { ISimpleSessionControl, StandaloneMatrixSDK } from "./serverSdk";
import { ITitleAndId } from "./standalone-interfaces";
import { IProjectContext, Project } from "./objects/Project";

import { ICategoryConfig } from "../core/common/businesslogic";
import { IBaseControl } from "../core/common/UI/Controls/BaseControl";
import { UIToolsConstants } from "../core/common/matrixlib/MatrixLibInterfaces";
import { FieldDescriptions } from "../core/common/businesslogic/FieldDescriptions";
import { IConfigApp } from "../core/admin/lib/IConfigApp";
import { SectionDescriptions } from "../core/common/businesslogic/FieldHandlers/Document/SectionDescriptions";
import { ItemSelectionTools } from "../core/common/UI/Tools/ItemSelectionView";
import { ReferenceTools } from "../core/common/UI/Tools/ItemReferenceView";

export type {
    ICategoryConfig,
    IBaseControl,
    TestManager,
    IDataStorage,
    IDB,
    ILineEditorLine,
    IDropdownOption,
    IPlugin,
    IStringMap,
    IProjectPageParam,
    IPluginPanelOptions,
    IWltItemWithLinks,
    IBaseControlOptions,
    IItem,
    IRestResult,
    IStringNumberMap,
    ISearchResult,
    XRGetProject_Needle_TrimNeedle,
    XRGetTodosAck,
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRTrimNeedleItem,
    XRTodo,
    XRTrimNeedle,
    XRGetProject_CategoryList_GetProjectStructAck,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRProjectInfo,
    IReference,
    ILabel,
    IItemSelectionParams,
    IAnyMap,
    INotificationConfig,
    IContextInformation,
    IContextPageConfigTab,
    XRTodoCount,
    XRGetProject_Todos_GetTodosAck,
    IItemChangeEvent,
    IExternalItem,
    IFieldParameter,
    IGlobalPrintFunctionParams,
    IPrintFunction,
    IPrintGlobals,
    GetSettingAck,
    IPrintFieldParams,
    IProcessResult,
    IConfigApp,
    IRiskControlOptions,
    IRiskParameter,
    IRiskValue,
    IRiskValueFactor,
    IRiskValueFactorWeight,
    IRiskValueMitigation,
    IRiskValueMitigationChange,
    IRiskValueMap,
    IRiskRender,
};
export {
    UIToolsConstants,
    FieldDescriptions,
    SectionDescriptions,
    PluginInterfaces,
    ProjectStorage,
    globalMatrix,
    matrixSession,
    app,
    ml,
    plugins,
    tableMath,
    refLinkStyle,
    refLinkTooltip,
    ControlState,
    ItemConfiguration,
    notificationSetting,
    defaultNotificationConfig,
    Notifications,
    pluginHooks,
    Tasks,
    MR1,
    matrixApplicationUI,
    PrintProcessor,
    ControlCore,
    PluginCore,
    LineEditor,
    ConfigPage,
    NotificationsBL,
    TodoTypes,
    ReferenceTools,
    ItemSelectionTools,
    printProcessorRegistry,
    RiskCalculator,
    RiskControlImpl,
};

export { MatrixSDK };
export { matrixsdk };
export { initializeSDK };

class MatrixSDK {
    // Session Management
    // Rest API support
    // UI support
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    initialize() {
        console.log("MatrixSDK.initialize");
    }

    protected standalone: StandaloneMatrixSDK;

    constructor(
        protected config: Configuration,
        protected baseRestUrl: string,
        protected matrixBaseUrl: string,
    ) {
        let adaptor = new (class implements ISimpleSessionControl {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            getCsrfCookie() {
                return matrixSession.getCsrfCookie();
            }

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            setComment(comment: string) {
                matrixSession.setComment(comment);
            }
            getComment(): string {
                return matrixSession.getComment();
            }

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            setProject(project: string) {
                matrixSession.setProject(project);
            }
            // @ts-ignore TODO: update type in ISimpleSessionControl and fix the errors
            getProject(): string | null {
                return matrixSession.getProject();
            }

            getDefaultProjectContext(): IProjectContext {
                const context = {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    getItemConfig: () => {
                        return globalMatrix.ItemConfig;
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    getJsonTools: () => {
                        return ml.JSON;
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    getLogger: () => {
                        return ml.Logger;
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    getLabelManager: () => {
                        return ml.LabelTools;
                    },
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    getTestManagerConfig: () => {
                        // Reinitialize because it can happen in the admin client that mTM was
                        // never properly set up.
                        mTM.getConfiguration().initialize(globalMatrix.ItemConfig);
                        return mTM.getConfiguration();
                    },
                };
                return context;
            }
        })();

        this.standalone = new StandaloneMatrixSDK(
            config,
            // @ts-ignore TODO: update type in ISimpleSessionControl and fix the errors
            adaptor,
            globalMatrix.ItemConfig,
            matrixBaseUrl,
            ml.Logger,
            ml.JSON,
            new SimpleItemTools(),
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public setComment(comment: string) {
        this.standalone.setComment(comment);
    }

    public getComment(): string {
        return this.standalone.getComment();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async setProject(project: string) {
        await this.standalone.setProject(project);
    }

    public getProject(): string {
        return this.standalone.getProject();
    }

    public async getProjects(): Promise<string[]> {
        return this.standalone.getProjects();
    }

    public getUrlInfo(): [string, string] {
        return this.standalone.getUrlInfo();
    }

    /**
     * The session object contains a string that represents the "current project."
     * This convenience method calls openProject() with that string.
     * @returns A valid Project object.
     */
    public async openCurrentProjectFromSession(): Promise<Project | null> {
        return this.standalone.openCurrentProjectFromSession();
    }

    /**
     * Retrieve or create a Project object for the given project name.  The method is
     * asynchronous because it may require a trip to the server to retrieve project
     * configuration.
     * @param project
     * @returns A valid Project object
     */
    public async openProject(project: string): Promise<Project | null> {
        return this.standalone.openProject(project);
    }

    /**
     * Return server settings (also called customer settings).
     * @returns A GetSettingAck object describing all the server settings
     */
    public async getServerSettings(): Promise<GetSettingAck> {
        return this.standalone.getServerSettings();
    }

    /**
     * Put a server setting (also called a customer setting).
     * @param key the key to save the setting under
     * @param value the value of the setting
     */
    public async putServerSetting(key: string, value: string): Promise<string> {
        return this.standalone.putServerSetting(key, value);
    }

    /**
     * Log to the server-side metrics log.
     * @param key the key to log the information under
     * @param values a collection of key value pairs
     * @returns "Ok" or "missing endpoint"
     */
    public async logMetrics(key: string, values: object): Promise<string> {
        return this.standalone.logMetrics(key, values);
    }

    /**
     * get an item from the database as json object.
     *
     * Use: await api.getItem("F-DOC-1")
     *
     * @param itemId the id of the item like "REQ-1" or a specific version like "REQ-1-v1"
     * @throws error in case the itemId is bad.
     * @returns Promise to json object with all fields, links and labels
     */
    public async getItem(itemId: string): Promise<IItem> {
        return this.standalone.getItem(itemId);
    }

    /**
     * get the initial tree structure from a project. Project must be set first.
     */
    public async getTree(): Promise<ITitleAndId[]> {
        return this.standalone.getTree();
    }

    /**
     * get a folder from the database, filling in it's children.
     * @param folderId  the id of the folder like "F-<type>-<id>"
     * @throws error if folderId is invalid
     * @returns Prommise to json object
     */
    public async getFolderChildren(folderId: string): Promise<ITitleAndId[]> {
        return this.standalone.getFolderChildren(folderId);
    }

    public async getDownlinks(itemId: string): Promise<IReference[]> {
        return this.standalone.getDownlinks(itemId);
    }

    public async getDownlinkIds(itemId: string): Promise<string[]> {
        return this.standalone.getDownlinkIds(itemId);
    }

    public async getUplinks(itemId: string): Promise<IReference[]> {
        return this.standalone.getUplinks(itemId);
    }

    public async getUplinkIds(itemId: string): Promise<string[]> {
        return this.standalone.getUplinkIds(itemId);
    }
    /**
     * search items
     *
     * @param term search expression, e.g. mrql:category=REQ
     * @param includeFields true to include fields
     * @param includeLinks true to include links
     * @param includeLabels true to include labels
     * @returns An array of ISearchResult results.
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async search(
        term: string,
        includeFields?: boolean,
        includeLinks?: boolean,
        includeLabels?: boolean,
        filter?: string,
    ): Promise<ISearchResult[]> {
        return this.standalone.search(term, includeFields, includeLinks, includeLabels, filter);
    }

    public async uploadProjectFile(url: string): Promise<AddFileAck> {
        return this.standalone.uploadProjectFile(url);
    }

    public async execute(payload: ExecuteParam): Promise<FolderAnswer> {
        return this.standalone.execute(payload);
    }

    public async getItemIdsInCategory(category: string): Promise<string[]> {
        return this.standalone.getItemIdsInCategory(category);
    }

    /**
     * gets the value of a field of an item from the database
     *
     * Use: await getField( "REQ-1", "description")
     *
     * @param itemId the id of the item like "REQ-1" or a specific version like "REQ-1-v1"
     * @param fieldName name of the field
     * @throws Error in case of invalid item or field
     * @returns Promise to the value of the field
     */
    public async getField(itemId: string, fieldName: string): Promise<unknown> {
        return this.standalone.getField(itemId, fieldName);
    }

    /**
     * set a field of an item in the database
     *
     * Use: await api.setField("PROC-83", "plain english", "x");
     *
     * @param itemId itemId the id of the item like "REQ-1"
     * @param fieldName name of the field
     * @param value value of the field
     * @throws Error in case of invalid itemId or fieldName
     * @returns Promise to the updated item
     */
    public async setField(itemId: string, fieldName: string, value: string): Promise<IItemGet> {
        return this.standalone.setField(itemId, fieldName, value);
    }

    public async setTitle(itemId: string, value: string): Promise<IItemGet> {
        return this.standalone.setTitle(itemId, value);
    }

    /**
     * sets multiple fields in the database
     *
     * Use: await api.setFields("PROC-83", [{fieldName:"plain english",value:"x"}]  )
     *
     * @param itemId itemId itemId the id of the item like "REQ-1"
     * @param data array of fieldName and value tupels
     * @throws Error in case of invalid id or fields
     * @returns the updated item
     */
    public async setFields(itemId: string, data: ISetField[]): Promise<IItemGet> {
        return this.standalone.setFields(itemId, data);
    }

    public async addDownLink(fromId: string, toId: string): Promise<string> {
        return this.standalone.addDownLink(fromId, toId);
    }

    public async deleteItem(itemId: string, force?: boolean): Promise<string> {
        return this.standalone.deleteItem(itemId, force);
    }

    public async deleteDownLink(fromId: string, toId: string): Promise<string> {
        return this.standalone.deleteDownLink(fromId, toId);
    }

    public async deleteDownLinks(fromId: string): Promise<string[]> {
        return this.standalone.deleteDownLinks(fromId);
    }

    public async deleteUpLinks(fromId: string): Promise<string[]> {
        return this.standalone.deleteUpLinks(fromId);
    }

    /**
     * create a new item in the database
     *
     * Use: createItem( "F-REQ-1", "my item", [{fieldName:"description",value:"x"}], ["labelx"], downlinks:["SPEC-1"], uplinks:[] )
     *
     * @param folder where to store the item
     * @param title name of the item
     * @param data array with fieldNames and values
     * @param labels list of labels to set
     * @param downlinks list of downlinks to create
     * @param uplinks list of uplinks to create
     * @returns the created item id
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async createItem(
        folder: string,
        title: string,
        data?: ISetField[],
        labels?: [],
        downlinks?: [],
        uplinks?: [],
    ): Promise<string> {
        return this.standalone.createItem(folder, title, data, labels, downlinks, uplinks);
    }

    /**
     * Creates a folder
     *
     * @param parent where to store the folder
     * @param title name of the folder
     * @param data array with fieldNames and values
     * @throws error in case of input error (bad fields, etc)
     * @returns Promise to the item id of folder
     */
    public async createFolder(parent: string, title: string, data?: ISetField[]): Promise<string> {
        return this.standalone.createFolder(parent, title, data);
    }

    public async getItemIdByTitle(category: string, title: string): Promise<string | null> {
        return this.standalone.getItemIdByTitle(category, title);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async copyItem(
        fromProject: string,
        fromItem: string,
        toProject: string,
        toFolder: string,
        copyLabels: boolean,
    ): Promise<CopyItemAck> {
        return this.standalone.copyItem(fromProject, fromItem, toProject, toFolder, copyLabels);
    }

    /**
     * Retrieve all changes in a project
     * @param project - project name
     * @param startAt - (optional) start the audit after N records
     * @param maxResults - (optional) retrieve N results per page
     * @param deleteOnly - (optional) if true, only returns actions of type delete
     * @param tech - (optional) if true, returns the underneath changes
     * @param auditIdMin - (optional) sets a minimum ID for audits as returned by GET calendar
     * @param auditIdMax - (optional) sets a maximum ID for audits
     * @param noReport - (optional) if true, avoid reports in the output
     * @param noImport - (optional) if true, avoid imports in the output
     * @param include - (optional) a comma-seperated list of actions to include (delete,undelete,add,edit,...)
     * @param resolveRef - (optional) if true, resolve item IDs into refs
     * @param itemRef - (optional) restrict the audit to only those mentioning this item
     * @returns a TrimAuditList structure
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async getProjectAudit(
        project: string,
        startAt?: number,
        maxResults?: number,
        deleteOnly?: boolean,
        tech?: boolean,
        auditIdMin?: number,
        auditIdMax?: number,
        noReport?: boolean,
        noImport?: boolean,
        include?: string,
        resolveRef?: boolean,
        itemRef?: string,
    ): Promise<TrimAuditList> {
        return this.standalone.getProjectAudit(
            project,
            startAt,
            maxResults,
            deleteOnly,
            tech,
            auditIdMin,
            auditIdMax,
            noReport,
            noImport,
            include,
            resolveRef,
            itemRef,
        );
    }

    // Shim functions to avoid exposing globals from this file.
    // TODO: They should become better integrated with this api.
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getRestProject(restQuery: string) {
        return restConnection.getProject(restQuery);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public getRestServer(restQuery: string) {
        return restConnection.getServer(restQuery);
    }

    public isQMSProject(project: string): boolean {
        return matrixSession.isQMSProject(project);
    }
    public getUser(): string {
        return matrixSession.getUser();
    }
    // default value for customer setting can be pretty much anything
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCustomerSettingJSON(s: string, defaultValue?: any): unknown {
        return matrixSession.getCustomerSettingJSON(s, defaultValue);
    }
    public serverConfig(): XRGetProject_StartupInfo_ListProjectAndSettings {
        return matrixSession.serverConfig;
    }
    public getImgFromProject(pref: string): string {
        return matrixSession.getImgFromProject(pref);
    }
    public getProjectList(readOrWriteOnly: boolean): XRProjectType[] {
        return matrixSession.getProjectList(readOrWriteOnly);
    }

    public getTestManager(): TestManager {
        return mTM;
    }

    static getLabelDefinitions(cats: string[]): ILabel[] {
        return ml.LabelTools.getLabelDefinitions(cats);
    }

    public createNewItemConfig(): ItemConfiguration {
        return this.standalone.createNewItemConfig();
    }
    public getItemConfig(): ItemConfiguration {
        return this.standalone.getItemConfig();
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public setItemConfig(newItemConfig: ItemConfiguration) {
        setIC(newItemConfig);
        this.standalone.setItemConfig(newItemConfig);
    }
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function RehomeExports() {
    /*
      We expose a d.ts file for the whole build. It bundles up all the d.ts in the system.
      However, this bundle makes it appear as if all of the items in it come from ONE
      MODULE. Our decision is to make this module (./ts/sdk/client) be the place for
      that. Well, there might be another one for admin things. But in order to make that
      work, we do need to create a synthetic module that exposes all of the types we expose
      at the top of the file.

      Our long term goal is not to expose these types, so this RehomeExports() function should
      become simpler with time.
    */
}

let matrixsdk: MatrixSDK;

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function initializeSDK() {
    let c: Configuration = new Configuration();
    matrixsdk = new MatrixSDK(c, globalMatrix.matrixRestUrl, globalMatrix.matrixBaseUrl);
    matrixsdk.initialize();

    // @ts-ignore TODO: minimize use of the globals
    globalThis.matrixsdk = matrixsdk;
    RehomeExports();
}

initializeSDK();
