import { satisfies, coerce } from "semver";

// because source code for SDK lives here and not in SDK repo, I think that's the only way to define the version
// TODO: replace it with something like `process.env.npm_package_version` once SDK is extracted
export const SDK_VERSION = "2.5.0";
export const SDK_SETTINGS_KEY = "sdk";

interface IValidateSdkVersionArgs {
    sdkVersion: string;
    serverVersion: string;
    settingsMinVersion?: string | null;
}

export function validateSdkVersion({
    sdkVersion,
    serverVersion,
    settingsMinVersion,
}: IValidateSdkVersionArgs): boolean {
    const matchServerVersion = satisfies(sdkVersion, getServerVersionRule(serverVersion));
    const biggerThanMinVersion = settingsMinVersion
        ? satisfies(sdkVersion, `>=${removePrereleaseTags(settingsMinVersion)}`)
        : true;

    if (matchServerVersion && biggerThanMinVersion) {
        return true;
    }

    throw new Error(
        `Please use a version of the Matrix Requirements SDK that matches version ${serverVersion}${
            settingsMinVersion ? ` and/or bigger than ${settingsMinVersion}` : ""
        }.`,
    );
}

// replacing patch version with "x", e.g. 2.4.0 -> 2.4.x
function getServerVersionRule(version: string): string {
    return removePrereleaseTags(version)
        .split(".")
        .map((number, index) => (index === 2 ? "x" : number))
        .join(".");
}

// e.g. 2.4.0.999-11579ba -> 2.4.0
function removePrereleaseTags(version: string): string {
    const coercedVersion = coerce(version);

    if (!coercedVersion) {
        throw new Error(`Version ${version} is invalid.`);
    }

    return coercedVersion.format();
}
