import { Item } from "./Item";
import { Category, ItemFieldMask } from "./Category";
import { IItemGet } from "../../core/globals";
import { FieldDescriptions } from "../../core/common/businesslogic/FieldDescriptions";
import { DHFFieldHandler } from "../../core/common/businesslogic/FieldHandlers/DHFFieldHandler";
import { SectionDescriptions } from "../../core/common/businesslogic/FieldHandlers/Document/SectionDescriptions";
import { Field } from "./Field";
import { DocFieldHandlerFactory } from "../../core/common/businesslogic/FieldHandlers/Document";
import { IDocFieldHandler } from "../../core/common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { JobFileWithUrl, JobsStatusWithUrl } from "../rest-api";

/**
 * DocItem is a subclass of Item which provides additional helper functions for managing a
 * Document (Items of Category DOC in a Matrix Instance).
 */
export class DocItem extends Item {
    /**
     * Construct a DocItem.
     * @param category
     * @param item
     * @param fieldMask
     */
    constructor(category: Category, item?: IItemGet, fieldMask?: ItemFieldMask) {
        super(category, item, fieldMask);
        // Verify that either fieldMask is not set or contains all fieds of the category
        if (fieldMask) {
            for (let field of this.getCategory().getFields()) {
                if (fieldMask.hasFieldId(field.id)) {
                    throw new Error("Fieldmask does not contain all fields of the category");
                }
            }
        }
    }

    /**
     * add a section to the end of a document
     * @param {title} Title of the section
     * @param {sectionType} Type of the section
     */
    addSection(title: string, sectionType: string): DHFFieldHandler {
        let documentOptionIndex = this.getDHFFields().findIndex(
            (section) =>
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                section.getHandler<DHFFieldHandler>().innerDataHandler.getFieldType() ==
                SectionDescriptions.section_document_options,
        );
        let handler = null;
        if (documentOptionIndex > -1) {
            handler = this.insertSection(documentOptionIndex, title, sectionType);
        } else {
            this.addDocumentOptions();
            handler = this.insertSection(0, title, sectionType);
        }
        return handler;
    }

    /**
     * Get the next section that needs to be filled
     * */
    getNextDHFFieldName(): string {
        let nextFieldToFill = "";
        let dhfSections = this.getDHFFields();
        for (let field of dhfSections) {
            let type = field.getHandler<DHFFieldHandler>().innerDataHandler.getFieldType();
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (type == SectionDescriptions.section_hidden) {
                nextFieldToFill = field.getFieldName();
                break;
            }
        }
        return nextFieldToFill;
    }

    /**
     * Get the list of DHF fields. This list only includes actual DHF fields,
     * not the "hidden" ones.
     * @returns DHF fields sorted by name (dhf00, dhf01, etc).
     */
    getDHFFields(): Field[] {
        return this.getFieldsByType(FieldDescriptions.Field_dhf).sort((a, b) => {
            return a.getFieldName().localeCompare(b.getFieldName());
        });
    }

    /**
     * It's helpful to see the names of DHF fields that would show up in the
     * UI for the DOC. Then fields can be retrieved by these names.
     * @returns A list of DOC UI field names (not "dhf01" but "Signatures",
     *     for example).
     */
    getDHFFieldInnerNames(): string[] {
        return this.getDHFFields().map((field) => {
            return field.getHandler<DHFFieldHandler>().innerDataHandler.getFieldName();
        });
    }

    /**
     * Retrieve an array of DOC DHF fields with the given UI name.
     * @param name
     * @returns An array of Field objects from the Item.
     */
    getDHFFieldsByInnerName(name: string): Field[] {
        const filteredFields = this.getDHFFields().filter((field) => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return field.getHandler<DHFFieldHandler>().innerDataHandler.getFieldName() == name;
        });
        return filteredFields;
    }

    /**
     * It is often convenient to work with the "inner field" of a dhf field,
     * where the configuration and values lie.
     * @returns An array of IDocFieldHandlers[]. The length is the number of valid fields.
     */
    getInnerDHFFields(): IDocFieldHandler[] {
        return this.getDHFFields()
            .filter((field) => !this.isHiddenDHFField(field))
            .map((field) => field.getHandler<DHFFieldHandler>().innerDataHandler);
    }

    /**
     * This method helps you know the most appropriate FieldHandler class to use
     * to manipulate the doc field.
     * @param handler
     * @returns the name of the handler class.
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getDocFieldHandlerClassName(handler: IDocFieldHandler) {
        return handler.constructor.name;
    }

    private isHiddenDHFField(field: Field): boolean {
        const res: boolean =
            field.getHandler<DHFFieldHandler>().innerDataHandler.getFieldType() === SectionDescriptions.section_hidden;
        return res;
    }

    /** insert a section at a given position
     * @param {number} number Position of the section
     * @param {sectionName} sectionName Name of the section
     * @param {sectionType} sectionType Type of the section
     * @returns the DHFFieldHandler inserted.
     * */
    insertSection(number: number, sectionName: string, sectionType: string): DHFFieldHandler {
        // Create the section
        let sections = this.getDHFFields();
        if (number < 0) {
            // Negative numbers are counted from the end
            number = sections.length + number;
        }

        if (number >= sections.length || number < 0) {
            throw new Error(
                "Cannot insert section at position " +
                    number +
                    " because there are only " +
                    sections.length +
                    " sections",
            );
        }

        // Get the count of non hidden sections
        const that = this;
        let nonHiddenSectionsCount = sections.filter((section) => !that.isHiddenDHFField(section)).length;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (nonHiddenSectionsCount == sections.length) {
            throw new Error("Cannot insert section at position " + number + " because there are no section left");
        }

        if (number > nonHiddenSectionsCount + 1) {
            throw new Error(
                "Cannot insert section at position " + number + " because there will be a hidden section in between",
            );
        }

        for (let i = sections.length - 1; i > number; i--) {
            let section = sections[i];
            let previous = sections[i - 1];
            section
                .getHandler<DHFFieldHandler>()
                .setInnerFieldHandler(previous.getHandler<DHFFieldHandler>().innerDataHandler);
        }

        let section = sections[number];
        let config = DocFieldHandlerFactory.GetDHFFieldConfig(this.getCategory().getItemConfig(), sectionType, {});
        let innerDataHandler = DocFieldHandlerFactory.createHandler(this.getCategory().getItemConfig(), {
            type: sectionType,
            ctrlConfig: config,
        });
        innerDataHandler.setFieldName(sectionName);
        section.getHandler<DHFFieldHandler>().setInnerFieldHandler(innerDataHandler);
        let fieldName = section.getFieldName();

        this.addMandatoryFields();

        return this.getSingleFieldByName(fieldName).getHandler<DHFFieldHandler>();
    }

    /**
     * Remove a section at a given position
     * @param number The position of the element to remove.
     * @throws Error if number is out of range.
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    removeSection(number: number) {
        let sections = this.getDHFFields();
        if (number < 0) {
            // Negative numbers are counted from the end
            number = sections.length + number;
        }

        if (number >= sections.length || number < 0) {
            throw new Error(
                "Cannot insert remove at position " +
                    number +
                    " because there are only " +
                    sections.length +
                    " sections",
            );
        }
        for (let i = number; i < sections.length - 1; i++) {
            let currentSection = sections[i];
            let nextSection = sections[i + 1];
            currentSection
                .getHandler<DHFFieldHandler>()
                .setInnerFieldHandler(nextSection.getHandler<DHFFieldHandler>().innerDataHandler);
            if (
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                nextSection.getHandler<DHFFieldHandler>().innerDataHandler.getFieldType() !=
                SectionDescriptions.section_hidden
            ) {
                nextSection.getHandler<DHFFieldHandler>().setInnerFieldHandler(
                    DocFieldHandlerFactory.createHandler(this.getCategory().getItemConfig(), {
                        type: SectionDescriptions.section_hidden,
                        ctrlConfig: {},
                    }),
                );
            }
        }

        // Make sure that we still have a document options section
        this.addMandatoryFields();
    }

    private async exportTo(
        type: "pdf" | "docx" | "html",
        progressReporter?: (jobId: number, jobDetails: JobsStatusWithUrl) => void,
    ): Promise<string> {
        const project = this.getCategory().getProject();
        let jobFiles = await project.generateDocument(type, this.getId(), progressReporter);
        for (let jobFile of jobFiles) {
            if (jobFile.visibleName?.endsWith(type)) {
                return jobFile.restUrl || "";
            }
        }
        return "not found";
    }

    /** Generate a html document
     * @return {url} the URL of the generated document */
    async toHTML(progressReporter?: (jobId: number, jobDetails: JobsStatusWithUrl) => void): Promise<string> {
        return this.exportTo("html", progressReporter);
    }

    /** Generate a pdf document
     * @return {url} the URL of the generated document */ async toPDF(
        progressReporter?: (jobId: number, jobDetails: JobsStatusWithUrl) => void,
    ): Promise<string> {
        return this.exportTo("pdf", progressReporter);
    }
    /** Generate a docx  document
     * @return {url} the URL of the generated document */
    async toDOCx(progressReporter?: (jobId: number, jobDetails: JobsStatusWithUrl) => void): Promise<string> {
        return this.exportTo("docx", progressReporter);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addMandatoryFields() {
        for (let field of this.getCategory().getFields()) {
            // The mask influences what we send out.
            if (this.getFieldMask().hasFieldId(field.id)) {
                const myField = this.getFieldById(field.id);
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (field.fieldType == FieldDescriptions.Field_reportId) {
                    myField?.getHandlerRaw().initData("dhf_generic");
                }
            }
        }

        // Document options should be the last field

        let sections = this.getDHFFields();
        let foundDocumentOptions = sections.find(
            (section) =>
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                section.getHandler<DHFFieldHandler>().innerDataHandler.getFieldType() ==
                SectionDescriptions.section_document_options,
        );
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (foundDocumentOptions == undefined) {
            // Document options is something we need to add
            //get the last field
            this.addDocumentOptions();
        } else {
            let documenOptionsInnerHandlerIndex = sections.indexOf(foundDocumentOptions);
            let documenOptionsInnerHandler = foundDocumentOptions.getHandler<DHFFieldHandler>().innerDataHandler;
            let lastField = documenOptionsInnerHandlerIndex;
            for (let i = documenOptionsInnerHandlerIndex; i < sections.length - 2; i++) {
                let field = sections[i];
                let nextField = sections[i + 1];
                if (
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    field.getHandler<DHFFieldHandler>().innerDataHandler.getFieldType() !=
                    SectionDescriptions.section_hidden
                ) {
                    field
                        .getHandler<DHFFieldHandler>()
                        .setInnerFieldHandler(nextField.getHandler<DHFFieldHandler>().innerDataHandler);
                } else {
                    lastField = i - 1;
                    break;
                }
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (lastField != -1) {
                sections[lastField].getHandler<DHFFieldHandler>().setInnerFieldHandler(documenOptionsInnerHandler);
            }
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private addDocumentOptions() {
        let field = this.getSingleFieldByName(this.getNextDHFFieldName());
        if (!field) {
            throw new Error("Cannot insert section because there are no section left");
        }
        let handler = field.getHandler<DHFFieldHandler>();

        let config = DocFieldHandlerFactory.GetDHFFieldConfig(
            this.getCategory().getItemConfig(),
            SectionDescriptions.section_document_options,
            {},
        );
        let innerDataHandler = DocFieldHandlerFactory.createHandler(this.getCategory().getItemConfig(), {
            type: SectionDescriptions.section_document_options,
            ctrlConfig: config,
        });
        handler.setInnerFieldHandler(innerDataHandler);
    }
}
