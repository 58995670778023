import {
    ICategoryConfig,
    ItemConfiguration,
    XRFieldTypeAnnotated,
} from "../../core/common/businesslogic/ItemConfiguration";
import { TestManagerConfiguration } from "../../core/common/businesslogic/TestManagerConfiguration";
import { Item } from "./Item";
import { Project } from "./Project";

export type { IFieldMaskOptions };
export { Category, ItemFieldMask, ItemsFieldMask };

/**
 * Options for creating a field mask for search functions.
 */
interface IFieldMaskOptions {
    /**
     * includeFields true by default. If false, no fields will be retrieved from the server.
     */
    includeFields?: boolean;

    /**
     * includeLabels true by default. If false, no label information will be retrieved from the server.
     */
    includeLabels?: boolean;

    /**
     * includeDownlinks false by default. If false, no information about downlinks will come from the server.
     */
    includeDownlinks?: boolean;

    /**
     * includeUplinks false by default. If false, no information about uplinks will come from the server.
     */
    includeUplinks?: boolean;
}

interface ICategoryItemOptions {
    filter?: string;
    treeOrder?: boolean;
    mask?: ItemsFieldMask;
}

/**
 * An ItemFieldMask contains a list of field ids valid within a particular
 * category. It should be created via the Category method createFieldMask().
 */
class ItemFieldMask {
    constructor(private fieldIds: number[]) {}

    hasFieldId(fieldId: number): boolean {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.fieldIds.filter((id) => id == fieldId).length > 0;
    }

    getFieldIds(): number[] {
        return this.fieldIds;
    }

    /**
     * Combine the other ItemFieldMask with this one.
     * @param other an ItemFieldMask
     */
    union(other: ItemFieldMask): void {
        for (let i of other.getFieldIds()) {
            if (!this.hasFieldId(i)) {
                this.fieldIds.push(i);
            }
        }
    }

    toString(): string {
        return this.fieldIds.join(",");
    }
}

/**
 * An ItemsFieldMask keeps track of fields masked by category, as well as some globally
 * masked Item fields (currently labels, uplinks and downlinks). This class is used
 * by the user of the SDK to narrow the set of fields brought down in a search query,
 * and then to allow the SDK to safely construct partial items from those results.
 */
class ItemsFieldMask {
    private masks: Map<string, ItemFieldMask>;
    private includeFields: boolean;
    private includeLabels: boolean;
    private includeDownlinks: boolean;
    private includeUplinks: boolean;

    constructor(options: IFieldMaskOptions = {}) {
        const {
            includeFields = true,
            includeLabels = true,
            includeDownlinks = false,
            includeUplinks = false,
        } = options;
        this.includeFields = includeFields;
        this.includeLabels = includeLabels;
        this.includeDownlinks = includeDownlinks;
        this.includeUplinks = includeUplinks;
        this.masks = new Map<string, ItemFieldMask>();
    }

    getIncludeFields(): boolean {
        return this.includeFields;
    }
    getIncludeLabels(): boolean {
        return this.includeLabels;
    }
    getIncludeDownlinks(): boolean {
        return this.includeDownlinks;
    }
    getIncludeUplinks(): boolean {
        return this.includeUplinks;
    }

    /**
     * Add fields to the mask for the given Category. If there is already a field mask for the
     * Category, its values will be combined with the new information via set union.
     * @param category
     * @param fieldIdsOrItemFieldMask either an ItemFieldMask object or an array of Category field ids
     * @throws Error if getIncludeFields() is false.
     * @returns this
     */
    addMask(category: Category, fieldIdsOrItemFieldMask: number[] | ItemFieldMask): ItemsFieldMask {
        if (!this.includeFields) {
            throw new Error(`This ItemsFieldMask is not configured to care about fields.`);
        }
        let newMask: ItemFieldMask;
        if (fieldIdsOrItemFieldMask instanceof ItemFieldMask) {
            newMask = fieldIdsOrItemFieldMask;
        } else {
            newMask = category.createFieldMask(fieldIdsOrItemFieldMask);
        }

        if (this.masks.has(category.getId())) {
            let cat = this.masks.get(category.getId());
            cat?.union(newMask);
        } else {
            this.masks.set(category.getId(), newMask);
        }
        return this;
    }

    /**
     * Adds fields to the Category mask by name. If the name doesn't exist or if there are more
     * than one fields with the name, an Error is thrown.
     * @param category
     * @param fieldNames
     * @throws Error if a field name exists more than once in the given Category or not at all.
     *         Also throws Error if getIncludeFields() is false.
     * @returns this
     */
    addMaskByNames(category: Category, fieldNames: string[]): ItemsFieldMask {
        if (!this.includeFields) {
            throw new Error(`This ItemsFieldMask is not configured to care about fields.`);
        }

        let fieldIds: number[] = [];
        for (let name of fieldNames) {
            const catFieldIds = category.getFieldIdFromLabel(name);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (catFieldIds.length == 0) {
                throw new Error(`Unable to find field name ${name} in category ${category.getId()}`);
            }
            if (catFieldIds.length > 1) {
                throw new Error(`Multiple fields with name ${name} in category ${category.getId()}`);
            }
            fieldIds.push(catFieldIds[0]);
        }
        return this.addMask(category, fieldIds);
    }

    /**
     * Returns an ItemFieldMask for the given Category if it exists
     * @param categoryId
     * @returns null if there is no mask for the given Category.
     */
    getCategoryMask(categoryId: string): ItemFieldMask | null {
        return this.masks.get(categoryId) || null;
    }

    /**
     * Suitable to send to the server for a search query.
     * @returns A comma-seperated string of ids or "*" (which means all fields accepted)
     */
    getFieldMaskString(): string {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.masks.size == 0) {
            // If we have no masks, but fields are included, then return all fields.
            // Otherwise, no fields (empty string).
            return this.includeFields ? "*" : "";
        }
        let result: string = "";
        let firstKey = true;
        for (let key of this.masks.keys()) {
            if (!firstKey) {
                result += ",";
            }
            result += this.masks.get(key)?.toString();
            firstKey = false;
        }
        return result;
    }
}
/**
 * A Category represents a category within a project. It has various configuration
 * settings. It also has a list of fields for that category.
 */
class Category {
    private allFieldsFieldMask: ItemFieldMask;

    constructor(
        private category: string,
        private project: Project,
    ) {
        // Cache a mask for all fields since it may be created often.
        this.allFieldsFieldMask = new ItemFieldMask(this.getFields().map((c) => c.id));
    }

    getProject(): Project {
        return this.project;
    }
    getItemConfig(): ItemConfiguration {
        return this.project.getItemConfig();
    }
    getTestConfig(): TestManagerConfiguration {
        return this.project.getTestConfig();
    }

    getId(): string {
        return this.category;
    }

    getConfig(): ICategoryConfig {
        return this.project.getItemConfig().getItemConfiguration(this.category);
    }

    getFields(): XRFieldTypeAnnotated[] {
        return this.project.getItemConfig().getFields(this.category) || [];
    }

    /**
     * Return field ids from the Category which match the given label.
     * These labels are searched in a case-insensitive way.
     * @param label
     * @returns a non-empty array of field ids if the label is present in the Category.
     */
    getFieldIdFromLabel(label: string): number[] {
        let results: number[] = [];
        for (let field of this.getFields()) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (field.label.toLowerCase() == label.toLowerCase()) {
                results.push(field.id);
            }
        }
        return results;
    }

    isFolderCategory(): boolean {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.getId() == "FOLDER";
    }

    /**
     * An ItemFieldMask allows you to specify which fields out of the Category
     * fields of an Item should be considered valid.
     * @param fieldIds If specified, a valid set of field ids for this Category. Otherwise,
     *        the returned ItemFieldMask expresses that all fields in the Item are to be
     *        considered valid.
     * @throws throws an Error if any of the field ids specified in fieldIds do not exist in the Category.
     * @returns an ItemFieldMask.
     */
    createFieldMask(fieldIds?: number[]): ItemFieldMask {
        const fields = this.getFields();
        if (fieldIds) {
            // Validate that we have these fields.
            for (let f of fieldIds) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (fields.filter((c) => c.id == f).length == 0) {
                    throw new Error(`Field id ${f} not found in category ${this.category}`);
                }
            }
            return new ItemFieldMask(fieldIds);
        }
        return this.allFieldsFieldMask;
    }

    /**
     * Construct a search mask from chosen options
     * @param options
     * @returns an ItemsFieldMask object
     */
    constructSearchFieldMask(options: IFieldMaskOptions): ItemsFieldMask {
        return this.project.constructSearchFieldMask(options);
    }

    /**
     * Get all of the items of this Category.
     * @param options An optional ICategoryItemOptions describing search options.
     * @returns An array of Items of this Category, configured according to the options given.
     */
    async getItems(options?: ICategoryItemOptions): Promise<Item[]> {
        const filter = options?.filter ?? "";
        const treeOrder = options?.treeOrder ?? false;
        const mask = options?.mask ?? undefined;

        return this.project.searchForItems(`mrql:category=${this.category}`, filter, treeOrder, mask);
    }
}
