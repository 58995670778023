import isomorphicFetch from "isomorphic-fetch";
import { TodoTypes } from "./common/enums";

import { Category } from "./objects/Category";
import { DocItem } from "./objects/DocItem";
import { Field } from "./objects/Field";
import { Item } from "./objects/Item";
import { Project, IProjectContext } from "./objects/Project";
import { TreeFolder } from "./objects/TreeFolder";
import { uploadFile } from "./utils/upload";

import {
    AddFileAck,
    FolderAnswer,
    CopyItemAck,
    ExecuteParam,
    DefaultApi,
    ProjectInfo,
    ListProjectAndSettings,
    ProjectType,
    FancyFolder,
    TrimFolder,
    TrimItem,
    TrimNeedle,
    AddItemAck,
    GetTodosAck,
    ItemItemBody,
    ProjectItemBody,
    CreateReportJobAck,
    JobsStatusWithUrl,
    ProjectNeedleminimalBody,
    GetSettingAck,
    Metric,
    TrimAuditList,
    GetProjectSettingAck,
} from "./rest-api";
import { ITitleAndId, IProjectNeeds, IProjectSearchOptions } from "./standalone-interfaces";
import { Configuration } from "./configuration";

import {
    ISearchResult,
    ISetField,
    TestManager,
    IExternalItem,
    IItemChangeEvent,
    IWltItemWithLinks,
    Tasks,
    IDB,
    NotificationsBL,
    ICategoryConfig,
    BaseTableFieldHandler,
    BaseValidatedTableFieldHandler,
    CheckboxFieldHandler,
    CrosslinksFieldHandler,
    DateFieldHandler,
    DHFFieldHandler,
    DropdownFieldHandler,
    EmptyFieldHandler,
    GateFieldHandler,
    GenericFieldHandler,
    HyperlinkFieldHandler,
    ItemSelectionFieldHandler,
    ItemSelectionFieldHandlerFromTo,
    RichtextFieldHandler,
    SteplistFieldHandler,
    TestResultFieldHandler,
    TestStepsFieldHandler,
    TestStepsResultFieldHandler,
    TextlineFieldHandler,
    UserFieldHandler,
    IFileParam,
    IFileUploadProgress,
    IFileUploadResult,
} from "../core/common/businesslogic";
// if we import ItemConfiguration from ../core/common/businesslogic, we gonna import whole bunch of stuff besides it
// from this path. not quite sure why exactly it's happening
import { ItemConfiguration } from "../core/common/businesslogic/ItemConfiguration";
import { FieldDescriptions } from "../core/common/businesslogic/FieldDescriptions";
import { SectionDescriptions } from "../core/common/businesslogic/FieldHandlers/Document/SectionDescriptions";
import { TestManagerConfiguration } from "../core/common/businesslogic/TestManagerConfiguration";

import {
    IContextInformation,
    ISimpleItemTools,
    IJSONTools,
    ILoggerTools,
    ILabelManager,
} from "../core/common/matrixlib/MatrixLibInterfaces";
import { SimpleItemTools } from "../core/common/matrixlib/SimpleItemTools";
import { LoggerTools } from "../core/common/matrixlib/LoggerTools";
import { JSONTools } from "../core/common/matrixlib/JSONTools";
import { LabelManager } from "../core/common/matrixlib/LabelManager";

import {
    IStringMap,
    IItem,
    IRestResult,
    IStringNumberMap,
    IReference,
    IAnyMap,
    ControlState,
    IDataStorage,
    IItemIdParts,
    IItemGet,
    IGenericMap,
    IItemHistory,
    IItemPut,
} from "../core/globals";

import {
    XRGetProject_CategoryList_GetProjectStructAck,
    XRGetProject_Needle_TrimNeedle,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRGetProject_Todos_GetTodosAck,
    XRGetTodosAck,
    XRProjectInfo,
    XRTodo,
    XRTodoCount,
    XRTrimNeedle,
    XRTrimNeedleItem,
    XRPutProject_EditItem_TrimItem,
} from "../core/RestResult";

import { XCPostAddFolder } from "../core/RestCalls";
import { SDK_SETTINGS_KEY, SDK_VERSION, validateSdkVersion } from "./utils/versions";
import { SelectMode } from "../core/common/UI/Components/ProjectViewDefines";
import { ReviewControlColumns } from "../core/client/plugins/ScheduleReviewDefines";

export type {
    AddFileAck,
    FolderAnswer,
    CopyItemAck,
    ExecuteParam,
    ISetField,
    ICategoryConfig,
    TestManager,
    IDataStorage,
    IDB,
    IStringMap,
    IWltItemWithLinks,
    IItem,
    IRestResult,
    IStringNumberMap,
    ISearchResult,
    XRGetProject_Needle_TrimNeedle,
    XRGetTodosAck,
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRTrimNeedleItem,
    XRTodo,
    XRTrimNeedle,
    XRGetProject_CategoryList_GetProjectStructAck,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRProjectInfo,
    IReference,
    IAnyMap,
    IContextInformation,
    XRTodoCount,
    XRGetProject_Todos_GetTodosAck,
    IItemChangeEvent,
    IExternalItem,
    IProjectContext,
    ISimpleSessionControl,
    ITitleAndId,
    IProjectNeeds,
    IItemIdParts,
    IItemHistory,
    IItemGet,
    IItemPut,
    ILoggerTools,
    FancyFolder,
    GetTodosAck,
    GetSettingAck,
    CreateReportJobAck,
    JobsStatusWithUrl,
    IFileParam,
    IFileUploadProgress,
    IFileUploadResult,
};

// classes
export { SelectMode, ReviewControlColumns, SectionDescriptions, FieldDescriptions };

export type {
    ControlState,
    ItemConfiguration,
    Tasks,
    NotificationsBL,
    TodoTypes,
    Item,
    Field,
    Project,
    Category,
    DocItem,
    TreeFolder,
    SimpleItemTools,
    LoggerTools,
    JSONTools,
    LabelManager,
};

// handlers
export type {
    BaseTableFieldHandler,
    BaseValidatedTableFieldHandler,
    CheckboxFieldHandler,
    CrosslinksFieldHandler,
    DateFieldHandler,
    DHFFieldHandler,
    DropdownFieldHandler,
    EmptyFieldHandler,
    GateFieldHandler,
    GenericFieldHandler,
    HyperlinkFieldHandler,
    ItemSelectionFieldHandler,
    ItemSelectionFieldHandlerFromTo,
    RichtextFieldHandler,
    SteplistFieldHandler,
    TestResultFieldHandler,
    TestStepsFieldHandler,
    TestStepsResultFieldHandler,
    TextlineFieldHandler,
    UserFieldHandler,
};

interface ISimpleSessionControl {
    getCsrfCookie(): string;

    setComment(comment: string): void;
    getComment(): string;

    setProject(project: string): void;
    getProject(): string;

    /**
     * In the web app environment, where this api is used in a context with
     * existing global variables, the default project context should be
     * provided. It provides "live" access to globals of the current project.
     * @returns A valid IProjectContext or null if none is available.
     */
    getDefaultProjectContext(): IProjectContext | null;
}

interface ICreateConsoleAPIArgs {
    token: string;
    url: string;
    skipSdkVersionCheck?: boolean;
}

export async function createConsoleAPI({
    token,
    url,
    skipSdkVersionCheck,
}: ICreateConsoleAPIArgs): Promise<StandaloneMatrixSDK> {
    let config = new Configuration({ apiKey: token });
    let session = new (class implements ISimpleSessionControl {
        private comment?: string;
        private project?: string;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        getCsrfCookie() {
            return "";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        setComment(comment: string) {
            this.comment = comment;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        getComment() {
            return this.comment || "";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        setProject(project: string) {
            this.project = project;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        getProject() {
            return this.project || "";
        }

        getDefaultProjectContext(): null {
            return null;
        }
    })();
    const logger = new LoggerTools(
        (d) => d.toString(),
        (d) => d,
    );
    const json = new JSONTools(logger);
    const itemTools = new SimpleItemTools();
    const itemConfig = new ItemConfiguration(logger, json);
    const instance = new StandaloneMatrixSDK(config, session, itemConfig, url, logger, json, itemTools);

    if (!skipSdkVersionCheck) {
        await instance.validateSdkVersion();
    }

    return instance;
}

/**
 * If this file is loaded in a 2.3 environment, then this function provides an easy way
 * to sniff context from globals and create an sdk object.
 * @throws an Error if some of the environment variables can't be found.
 * @returns A StandaloneMatrixSDK
 */
export async function createFrom23Environment(skipSdkVersionCheck?: boolean): Promise<StandaloneMatrixSDK> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore compatibility related
    let matrixSession = globalThis["matrixSession"];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore compatibility related
    let IC = globalThis["IC"];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore compatibility related
    let ml = globalThis["ml"];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore compatibility related
    let matrixBaseUrl = globalThis["matrixBaseUrl"];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore compatibility related
    let baseRestUrl = globalThis["matrixRestUrl"];

    if (!matrixSession || !IC || !ml || !matrixBaseUrl || !baseRestUrl) {
        throw new Error("some global variables are missing");
    }

    // We create our own TestManager, because the 2.3 version operated differently to
    // our needs.
    const testManagerConfig = new TestManagerConfiguration();
    testManagerConfig.initialize(IC);

    let config = new Configuration();
    let adaptor = new (class implements ISimpleSessionControl {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        getCsrfCookie() {
            return matrixSession.getCsrfCookie();
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        setComment(comment: string) {
            matrixSession.setComment(comment);
        }
        getComment(): string {
            return matrixSession.getComment();
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        setProject(project: string) {
            matrixSession.setProject(project);
        }
        getProject(): string {
            return matrixSession.getProject();
        }

        getDefaultProjectContext(): IProjectContext {
            const context = {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getItemConfig: () => {
                    return IC;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getJsonTools: () => {
                    return ml.JSON;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getLogger: () => {
                    return ml.Logger;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getLabelManager: () => {
                    return ml.LabelTools;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getTestManagerConfig: () => {
                    testManagerConfig.initialize(IC);
                    return testManagerConfig;
                },
            };
            return context;
        }
    })();

    let instance = new StandaloneMatrixSDK(
        config,
        adaptor,
        IC,
        matrixBaseUrl,
        ml.Logger,
        ml.JSON,
        new SimpleItemTools(),
    );

    if (!skipSdkVersionCheck) {
        await instance.validateSdkVersion();
    }

    return instance;
}

class IsomorphicFetchWrapper {
    private myFetch: unknown;
    private log: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(private oldFetch: any) {
        this.log = [];
        let log = this.log;
        // TODO: add proper types
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        this.myFetch = async (...args: [any, any]) => {
            let [resource, config] = args;
            log.push(resource);
            const response = oldFetch(resource, config);
            return response;
        };
    }

    getLog(): string[] {
        return this.log;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFetch(): any {
        return this.myFetch;
    }
}

/**
 * StandaloneMatrixSDK is a connection to a Matrix Instance. It offers services to interact
 * with the Instance. A primary purpose beyond authenticating on the server is to provide access
 * to Project objects through openProject() or openCurrentProjectFromSession().
 */
export class StandaloneMatrixSDK implements IProjectNeeds {
    // Session Management
    // Rest API support
    // UI support
    protected instance: DefaultApi;
    private ItemConfig?: ItemConfiguration;
    protected labelManager: ILabelManager;
    protected baseRestUrl: string;
    public debug: boolean = false;
    private projectMap: Map<string, Project>;
    private fetchWrapper: IsomorphicFetchWrapper;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    constructor(
        protected config: Configuration,
        protected session: ISimpleSessionControl,
        initialItemConfig: ItemConfiguration,
        protected matrixBaseUrl: string,
        protected logger: ILoggerTools,
        protected json: IJSONTools,
        protected simpleItemTools: ISimpleItemTools,
    ) {
        this.baseRestUrl = `${matrixBaseUrl}/rest/1`;
        this.projectMap = new Map<string, Project>();
        this.fetchWrapper = new IsomorphicFetchWrapper(isomorphicFetch);
        this.instance = new DefaultApi(this.config, this.baseRestUrl, this.fetchWrapper.getFetch());
        this.setItemConfig(initialItemConfig);
        this.labelManager = new LabelManager(logger, json, () => {
            return this.getItemConfig();
        });
    }

    /**
     * Returns the base URL of the server, and the REST api endpoint.
     * @returns a tuple with [baseUrl, baseRestUrl]
     */
    public getUrlInfo(): [string, string] {
        return [this.matrixBaseUrl, this.baseRestUrl];
    }

    public getFetchLog(): string[] {
        return this.fetchWrapper.getLog();
    }

    public createNewItemConfig(): ItemConfiguration {
        return new ItemConfiguration(this.logger, this.json);
    }

    public getLabelManager(): ILabelManager {
        return this.labelManager;
    }

    public getItemConfig(): ItemConfiguration {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: think up a good default or update the type and occurrences
        return this.ItemConfig;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public setItemConfig(newItemConfig: ItemConfiguration) {
        this.ItemConfig = newItemConfig;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getHeadersForPost() {
        return {
            "x-csrf": this.session.getCsrfCookie(),
        };
    }

    // Called by setProject on project change.
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async initializeProject() {
        const p: ProjectInfo = await this.instance.projectGet(this.getProject(), 1);
        this.setItemConfig(this.createNewItemConfig());
        this.getItemConfig().init(<XRProjectInfo>p);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private log(arg: any) {
        if (this.debug) {
            this.logger.info(arg);
        }
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public setComment(comment: string) {
        this.session.setComment(comment);
    }

    public getComment(): string {
        return this.session.getComment();
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async setProject(project: string) {
        this.session.setProject(project);
        if (project) {
            await this.initializeProject();
        }
    }

    public getProject(): string {
        return this.session.getProject();
    }

    public async getProjects(): Promise<string[]> {
        let p: Promise<ListProjectAndSettings> = this.instance.rootGet(0);
        return p.then((result: ListProjectAndSettings) => {
            let projects: string[] = [];
            if (result.project) {
                result.project.forEach((a: ProjectType) => {
                    a.shortLabel && projects.push(a.shortLabel);
                });
            }
            return projects;
        });
    }

    /**
     * Return server settings (also called customer settings).
     * @returns A GetSettingAck object describing all the server settings
     */
    public async getServerSettings(): Promise<GetSettingAck> {
        return this.instance.allSettingGet();
    }

    /**
     * Put a server setting (also called a customer setting).
     * @param key the key to save the setting under
     * @param value the value of the setting
     */
    public async putServerSetting(key: string, value: string): Promise<string> {
        const options = { headers: this.getHeadersForPost() };
        return this.instance.allSettingPost(key, value, options);
    }

    /**
     * Return settings for a given project.
     * @param project The project name
     * @returns a GetProjectSettingAck object describing all the project settings
     */
    public async getProjectSettings(project: string): Promise<GetProjectSettingAck> {
        return this.instance.projectSettingGet(project);
    }

    /**
     * Put a project setting.
     * @param project the project
     * @param key the key to save the setting under
     * @param value the value of the setting
     */
    public async putProjectSetting(project: string, key: string, value: string): Promise<string> {
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectSettingPost(project, key, value, options);
    }

    /**
     * Log to the server-side metrics log.
     * @param key the key to log the information under
     * @param values a collection of key value pairs
     * @returns "Ok" or "missing endpoint"
     */
    public async logMetrics(key: string, values: object): Promise<string> {
        const options = { headers: this.getHeadersForPost() };
        if (values === undefined || values === null) {
            values = {};
        }
        const metric = {
            key: key,
            values: values,
        };
        try {
            let result: string = await this.instance.allMetricsPut(metric, options);
            return result;
        } catch (e) {
            // If the end point is not there, just return "missing endpoint"
            return "missing endpoint";
        }
    }

    protected parseRef(itemId: string): IItemIdParts {
        return this.parseRefForProject(this.getProject(), itemId);
    }

    private getType(itemId: string): string {
        let ir = this.parseRef(itemId);
        if (ir.type !== "") {
            return ir.type;
        }
        // no idea...
        return "";
    }

    /**
     * get an item from the database as json object.
     *
     * Use: await api.getItem("F-DOC-1")
     *
     * @param itemId the id of the item like "REQ-1" or a specific version like "REQ-1-v1"
     * @throws error in case the itemId is bad.
     * @returns Promise to json object with all fields, links and labels
     */
    public async getItem(itemId: string): Promise<IItem> {
        this.log(`get item "${itemId}`);

        let type = this.parseRef(itemId).type;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!type || this.getItemConfig().getCategories(true).indexOf(type) == -1) {
            const msg = `This is not possibly an item in this project: "${itemId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }
        return this.appGetItemAsync(this.getProject(), itemId);
    }

    public parseRefForProject(project: string, itemRef: string): IItemIdParts {
        return this.simpleItemTools.parseRef(itemRef, project, this.matrixBaseUrl);
    }

    public getItemFromProject(project: string, id: string): Promise<IItemGet> {
        return this.appGetItemAsync(project, id);
    }

    /**
     * get the initial tree structure from a project. Project must be set first.
     */
    public async getTree(): Promise<ITitleAndId[]> {
        return this.getTreeFromProject(this.getProject());
    }

    public async getFullTreeFromProject(projectName: string): Promise<FancyFolder[]> {
        const p: FancyFolder[] = await this.instance.projectTreeGet(projectName, "yes");
        return p;
    }

    public async getTreeFromProject(projectName: string): Promise<ITitleAndId[]> {
        let p: Promise<FancyFolder[]> = this.instance.projectTreeGet(projectName, "yes");
        return p.then((folders: FancyFolder[]) => {
            let result: ITitleAndId[] = [];
            folders.forEach((v: FancyFolder) => {
                const hasChildren = v.children && v.children.length > 0;
                result.push({ isFolder: !!hasChildren, title: v.title || "", id: v.id || "" });
            });
            return result;
        });
    }

    /**
     * get a folder from the database, filling in it's children.
     * @param folderId  the id of the folder like "F-<type>-<id>"
     * @throws error if folderId is invalid
     * @returns Promise to ITitleAndId array
     */
    public async getFolderChildren(folderId: string): Promise<ITitleAndId[]> {
        this.log(`get folder "${folderId}`);
        const ref = this.parseRef(folderId);
        if (!ref.isFolder) {
            const msg = `This is not a folder: "${folderId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }
        let type = ref.type;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!type || this.getItemConfig().getCategories(true).indexOf(type) == -1) {
            const msg = `This is not possibly a folder in this project: "${folderId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }
        return this.getFolderChildrenFromProject(this.getProject(), folderId);
    }

    public async getFolderChildrenFromProject(projectName: string, folderId: string): Promise<ITitleAndId[]> {
        this.log(`get folder ${folderId} from project ${projectName}`);
        const ref = this.parseRef(folderId);
        if (!ref.isFolder) {
            const msg = `This is not a folder: "${folderId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }
        const p: Promise<TrimFolder> = this.instance.projectItemFolderGet(projectName, folderId, 0, "", "yes");

        return p.then((value: TrimFolder) => {
            let result: ITitleAndId[] = [];
            // Harvest the children's IDs.
            if (value.itemList) {
                value.itemList.forEach((v: TrimFolder) => {
                    result.push({ isFolder: (v.isFolder || 0) > 0, title: v.title || "", id: v.itemRef || "" });
                });
            }
            return result;
        });
    }

    private parseItemJSON(itemId: string, result: XRPutProject_EditItem_TrimItem): IItemGet {
        let item: IItemGet = {
            id: itemId,
            title: result.title,
            type: this.getType(itemId),
            downLinks: [],
            upLinks: [],
            modDate: result.modDate,
            isUnselected: result.isUnselected,
            labels: result.labels ? result.labels : [],
            maxVersion: result.maxVersion,
        };

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (result.isFolder != undefined) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            item.isFolder = result.isFolder == 1;
            item.children = [];
        } else {
            item.isFolder = false;
        }

        if (result.docHasPackage) {
            item.docHasPackage = result.docHasPackage;
        }

        if (!result.maxVersion) {
            item.isDeleted = true;
        }

        if (result.fieldValList) {
            for (let fieldVal in result.fieldValList.fieldVal) {
                (<IGenericMap>item)[result.fieldValList.fieldVal[fieldVal].id.toString()] =
                    result.fieldValList.fieldVal[fieldVal].value;
            }
        }

        for (let idx = 0; result.downLinkList && idx < result.downLinkList.length; idx++) {
            const tol = result.downLinkList[idx].itemRef;

            item.downLinks?.push({
                to: this.parseRef(tol).id,
                title: result.downLinkList[idx].title,
                modDate: result.downLinkList[idx].modDate,
            });
        }

        for (let idx = 0; result.upLinkList && idx < result.upLinkList.length; idx++) {
            const tol = result.upLinkList[idx].itemRef;

            item.upLinks?.push({
                to: this.parseRef(tol).id,
                title: result.upLinkList[idx].title,
                modDate: result.upLinkList[idx].modDate,
            });
        }
        // copy original up list
        item.upLinkList = result.upLinkList;

        if (result.availableFormats) {
            item["availableFormats"] = result.availableFormats;
        }
        if (result.selectSubTree) {
            item["selectSubTree"] = result.selectSubTree;
        }
        if (result.requireSubTree) {
            item["requireSubTree"] = result.requireSubTree;
        }

        let hoi: IItemHistory[] = [];
        for (let idx = 0; result.itemHistoryList && idx < result.itemHistoryList.itemHistory.length; idx++) {
            let theAction = result.itemHistoryList.itemHistory[idx];
            let historyInfo: IItemHistory = {
                id: itemId,
                user: theAction.createdByUserLogin,
                action: theAction.auditAction,
                version: theAction.version,
                date: theAction.createdAt,
                dateUserFormat: theAction.createdAtUserFormat,
                title: theAction.title,
                comment: theAction.reason,
            };
            // now use the information that undeleted items have been deleted just before
            if (theAction.auditAction === "undelete") {
                if (result.itemHistoryList.itemHistory.length > idx + 1) {
                    let theDelete = result.itemHistoryList.itemHistory[idx + 1];
                    if (theDelete.auditAction !== "delete") {
                        historyInfo["deletedate"] = theDelete.deletedAtUserFormat;
                    }
                }
            }
            hoi.push(historyInfo);
        }
        item["history"] = hoi;
        return item;
    }

    private async appGetItemAsync(project: string, itemId: string): Promise<IItem> {
        const p: Promise<TrimItem> = this.instance.projectItemItemGet(project, itemId, 1);

        return p.then((value: TrimItem) => {
            if (value.isFolder) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore why do we set the property that doesn't exist in TrimItem?
                value["children"] = [];
            }
            const item = this.parseItemJSON(itemId, value as XRPutProject_EditItem_TrimItem);
            return item;
        });
    }

    public async getDownlinks(itemId: string): Promise<IReference[]> {
        this.log(`get downlinks of item "${itemId}`);

        const itemPromise = this.getItem(itemId);
        return itemPromise.then((value: IItem) => {
            return value.downLinks ? value.downLinks : [];
        });
    }

    public async getDownlinkIds(itemId: string): Promise<string[]> {
        this.log(`get downlink ids of item "${itemId}`);

        const links = this.getDownlinks(itemId);
        return links.then((value: IReference[]) => {
            return value.map((d) => d.to);
        });
    }

    public async getUplinks(itemId: string): Promise<IReference[]> {
        this.log(`get Uplinks of item "${itemId}`);

        const itemPromise: Promise<IItem> = this.getItem(itemId);
        return itemPromise.then((value: IItem) => {
            return value.upLinks ? value.upLinks : [];
        });
    }

    public async getUplinkIds(itemId: string): Promise<string[]> {
        this.log(`get uplink ids of item "${itemId}`);

        const links = this.getUplinks(itemId);
        return links.then((value: IReference[]) => {
            return value.map((d) => d.to);
        });
    }

    /**
     * search items
     *
     * @param term search expression, e.g. mrql:category=REQ
     * @param includeFields true to include fields
     * @param includeLinks true to include links
     * @param includeLabels true to include labels
     * @returns search results
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async search(
        term: string,
        includeFields?: boolean,
        includeLinks?: boolean,
        includeLabels?: boolean,
        filter?: string,
    ): Promise<ISearchResult[]> {
        this.log(`Search for "${term}"`);

        return this.appSearchAsync(
            this.getProject(),
            term,
            filter,
            true,
            includeFields ? "*" : undefined,
            undefined,
            includeLabels ?? false,
            includeLinks ?? false,
            includeLinks ?? false,
        );
    }

    /**
     * Move items to a particular folder.
     * @param project a valid project on the instance
     * @param folderId a valid folder id within the project
     * @param itemIds an array of itemIds
     * @returns the string "Ok" on success
     */
    public async moveItemsInProject(project: string, folderId: string, itemIds: string[]): Promise<string> {
        this.log(`Move items in ${project} to folder ${folderId}"`);
        const comment = this.getComment();
        const itemsString: string = itemIds.join(",");
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectMoveinFolderPost(project, folderId, comment, itemsString, options);
    }

    /**
     * Run a hook in a project
     * @param project
     * @param itemId
     * @param hookName
     * @param body
     */
    public async runHookInProject(project: string, itemId: string, hookName: string, body: string): Promise<string> {
        this.log(`Run hook in ${project} with item ${itemId} and hook name ${hookName}`);
        const options = { headers: this.getHeadersForPost() };
        const value = await this.instance.projectHookItemPost(project, itemId, hookName, body, options);
        try {
            return JSON.stringify(value);
        } catch (e) {
            return value;
        }
    }

    /**
     * Execute a search in the given project, returning matching item ids.
     * @param project
     * @param term
     * @returns an array of item ids.
     */
    public async searchIdsInProject(project: string, term: string): Promise<string[]> {
        this.log(`Search in ${project} for "${term}"`);

        let params: ProjectNeedleminimalBody = { search: term };
        const options = { headers: this.getHeadersForPost() };
        const results = await this.instance.projectNeedleminimalPost(params, project, options);
        return results;
    }

    public async searchItemsInProject(
        project: string,
        term: string,
        options?: IProjectSearchOptions,
    ): Promise<ISearchResult[]> {
        options = options || {};
        const filter = options?.filter ?? "";
        const fieldList = options?.fieldList ?? "*";
        const includeLabels = options?.includeLabels ?? true;
        const includeDownlinks = options?.includeDownlinks ?? false;
        const includeUplinks = options?.includeUplinks ?? false;
        const treeOrder = options?.treeOrder ?? false;
        return this.appSearchAsync(
            project,
            term,
            filter,
            true,
            fieldList, // "*" for all fields
            undefined,
            includeLabels,
            includeDownlinks,
            includeUplinks,
            treeOrder,
        );
    }

    public async uploadProjectFile(url: string): Promise<AddFileAck> {
        return this.uploadFileToProject(this.getProject(), url);
    }

    public async uploadFileToProject(project: string, url: string): Promise<AddFileAck> {
        const options = { headers: this.getHeadersForPost() };
        let result: AddFileAck = await this.instance.projectFilePost(project, url, options);
        return result;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async uploadFileServerAsync(
        axiosLib: unknown,
        file: unknown,
        progress: (p: IFileUploadProgress) => void,
        target: string,
        urlSuffix: string,
    ): Promise<AddFileAck> {
        let url = target ? target + urlSuffix : this.baseRestUrl + "/all" + urlSuffix;

        const headers = {
            ...this.getHeadersForPost(),
            Authorization: this.config.apiKey,
        };

        // TODO: We don't use our {this.instance} wrapper to do the upload, because the upload method
        // can't deal with binary body data at this time.
        return await uploadFile(axiosLib, url, headers, progress, file);
    }

    public async uploadLocalFileToProject(
        project: string,
        axiosLib: unknown,
        file: IFileParam,
        progress: (p: IFileUploadProgress) => void,
    ): Promise<AddFileAck> {
        const url = this.baseRestUrl + "/" + project;
        return this.uploadFileServerAsync(axiosLib, file, progress, url, "/file");
    }

    public async executeInProject(project: string, payload: ExecuteParam): Promise<FolderAnswer> {
        const options = { headers: this.getHeadersForPost() };
        let items = await this.instance.projectExecutePost(project, payload, options);
        return items;
    }

    public async execute(payload: ExecuteParam): Promise<FolderAnswer> {
        return this.executeInProject(this.getProject(), payload);
    }

    /**
     * The session object contains a string that represents the "current project."
     * This convenience method calls openProject() with that string.
     * @returns A valid Project object, or null if the session has no project.
     */
    public async openCurrentProjectFromSession(): Promise<Project | null> {
        const project = this.session.getProject();
        return this.openProject(project);
    }

    /**
     * Retrieve or create a Project object for the given project name.  The method is
     * asynchronous because it may require a trip to the server to retrieve project
     * configuration.
     * @param project a valid string.
     * @returns A valid Project object, or null if the project name is undefined.
     */
    public async openProject(project: string): Promise<Project | null> {
        if (!project) {
            return null;
        }

        if (this.projectMap.has(project)) {
            let proj = this.projectMap.get(project);
            return proj || null;
        }

        // If we are running in the web application context, and the user asks to open the current
        // project, provide them with the current web application globals. Otherwise, create a new
        // context on the fly. That requires a server call to get the item configuration information.
        let context: IProjectContext | null = null;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (this.session.getProject() == project && this.session.getDefaultProjectContext() != null) {
            context = this.session.getDefaultProjectContext();

            // The default context, although it may have the name of the project the user
            // is asking for, may not really be "loaded". Check for that case.
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (context?.getItemConfig().getCategories().length == 0) {
                const p: ProjectInfo = await this.instance.projectGet(project, 1);
                context.getItemConfig().init(<XRProjectInfo>p);
                context.getTestManagerConfig().initialize(context.getItemConfig());
            }
        } else {
            const p: ProjectInfo = await this.instance.projectGet(project, 1);
            let config = this.createNewItemConfig();
            config.init(<XRProjectInfo>p);
            const labelManager = new LabelManager(this.logger, this.json, () => config);
            const testManagerConfig = new TestManagerConfiguration();
            testManagerConfig.initialize(config);
            context = {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getItemConfig: () => {
                    return config;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getJsonTools: () => {
                    return this.json;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getLogger: () => {
                    return this.logger;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getLabelManager: () => {
                    return labelManager;
                },
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                getTestManagerConfig: () => {
                    return testManagerConfig;
                },
            };
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore leaving as is for now, not sure we should support passing null as a context,
        // but it's exactly what can happen there
        const proj = new Project(this, project, context);
        this.projectMap.set(project, proj);
        return proj;
    }

    private parseSearchResult(needle: XRTrimNeedleItem, fieldList?: string): ISearchResult {
        let fullitem = this.parseRef(needle.itemOrFolderRef);
        const that = this;
        let sr: ISearchResult = {
            itemId: fullitem.id,
            version: fullitem.version,
            title: needle.title,
            downlinks: [],
            uplinks: [],
            labels: [],
        };
        if (fieldList && fieldList.length > 0) {
            sr.fieldVal = needle.fieldVal;
        }
        if (needle.downLinkList) {
            for (let link of needle.downLinkList) {
                sr.downlinks.push(that.parseRef(link.itemRef).id);
            }
        }
        if (needle.upLinkList) {
            for (let link of needle.upLinkList) {
                sr.uplinks.push(that.parseRef(link.itemRef).id);
            }
        }
        if (needle.labels) {
            let labels = needle.labels.split(",");
            for (let label of labels) {
                sr.labels.push(label.substr(1, label.length - 2));
            }
        }
        if (needle.creationDate) {
            sr["creationDate"] = needle.creationDate;
        }
        return sr;
    }

    // TODO: crossProject is not handled (it is a server query, not a project query).
    // TODO: consume arguments as object
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async appSearchAsync(
        project: string,
        term: string,
        filter?: string,
        ignoreFilters?: boolean,
        fieldList?: string,
        crossProject?: string,
        labels?: boolean,
        down?: boolean,
        up?: boolean,
        treeOrder?: boolean,
    ): Promise<ISearchResult[]> {
        let linksReq = "";
        if (down && up) {
            linksReq = "up,down";
        } else if (down) {
            linksReq = "down";
        } else if (up) {
            linksReq = "up";
        }
        const options = { headers: this.getHeadersForPost() };
        const p: Promise<TrimNeedle> = this.instance.projectNeedlePost(
            {
                id: "",
                search: term,
                filter: filter,
                fieldsOut: fieldList,
                labels: labels ? 1 : 0,
                links: linksReq,
                treeOrder: treeOrder ? 1 : 0,
            },
            project,
            options,
        );
        return p.then((result: TrimNeedle) => {
            let hoi: ISearchResult[] = [];
            const needles = result.needles || [];
            for (let idx = 0; idx < needles.length; idx++) {
                hoi.push(this.parseSearchResult(needles[idx] as XRTrimNeedleItem, fieldList));
            }
            return hoi;
        });
    }

    public async getItemIdsInCategory(category: string): Promise<string[]> {
        this.log(`get items of type "${category}"`);
        let items = await this.search("mrql:category=" + category);
        return items.map((item) => item.itemId);
    }

    /**
     * gets the value of a field of an item from the database
     *
     * Use: await getField( "REQ-1", "description")
     *
     * @param itemId the id of the item like "REQ-1" or a specific version like "REQ-1-v1"
     * @param fieldName name of the field
     * @throws Error in case of invalid item or field
     * @returns Promise to the value of the field
     */
    public async getField(itemId: string, fieldName: string): Promise<unknown> {
        this.log(`get field "${fieldName} of item "${itemId}" `);

        let type = this.parseRef(itemId).type;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!type || this.getItemConfig().getCategories(true).indexOf(type) == -1) {
            const msg: string = `This is not possibly an item in this project: "${itemId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }
        let fieldId = this.getItemConfig().getFieldId(type, fieldName);
        if (!fieldId) {
            const msg: string = `"${fieldName}" is not a field of this item "${itemId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }
        let itemPromise: Promise<IItem> = this.appGetItemAsync(this.getProject(), itemId);
        return itemPromise.then((value: IItem) => {
            return value[fieldId];
        });
    }

    /**
     * set a field of an item in the database
     *
     * Use: await api.setField("PROC-83", "plain english", "x");
     *
     * @param itemId itemId the id of the item like "REQ-1"
     * @param fieldName name of the field
     * @param value value of the field
     * @throws Error in case of invalid itemId or fieldName
     * @returns Promise to the updated item
     */
    public async setField(itemId: string, fieldName: string, value: string): Promise<IItemGet> {
        this.log(`set field "${fieldName} of item "${itemId}" `);
        return this.setFields(itemId, [{ fieldName: fieldName, value: value }]);
    }

    public async setTitle(itemId: string, value: string): Promise<IItemGet> {
        this.log(`set title of item "${itemId}" `);
        let update = {
            id: itemId,
            onlyThoseFields: 1,
            onlyThoseLabels: 1,
            title: value,
        };
        let type = this.parseRef(itemId).type;
        if (!type) {
            const msg: string = `This is not possibly an item in this project: "${itemId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }

        return this.appUpdateItemInDBAsync(this.getProject(), update, "edit");
    }

    private appUpdateItemInDBAsync(
        project: string,
        itemJson: IItemPut,
        auditAction: string,
        currentVersion?: number,
    ): Promise<IItemGet> {
        const comment = this.getComment();
        let body: ItemItemBody = {
            reason: comment,
            currentVersion: currentVersion,
            linksAreComplete: 1,
            itemProperties: {},
        };
        if (auditAction) {
            body["auditAction"] = auditAction;
        }
        const regex = /fx[0-9]+/;
        for (let par in itemJson) {
            if (!itemJson.hasOwnProperty(par)) {
                continue;
            }
            if (body.hasOwnProperty(par)) {
                continue;
            }
            if (par === "type") {
                continue;
            }
            if (par === "category") {
                continue;
            }
            if (par === "links") {
                continue;
            }
            if (par === "id") {
                continue;
            }
            if (par === "downLinks" || par === "upLinks") {
                // These are sent in fields linksDown and linksUp.
                continue;
            }

            if (isNaN(Number(par))) {
                // it's attribute other than a field
                (<IGenericMap>body)[par] = (<IGenericMap>itemJson)[par];
            } else {
                // it's a number so we assume it's a field
                (<IGenericMap>body).itemProperties["fx" + par] = (<IGenericMap>itemJson)[par];
            }

            // If itemJson already has "fx" fields, we need to put those in the fxFields bucket.
            if (regex.test(par)) {
                (<IGenericMap>body).itemProperties[par] = (<IGenericMap>itemJson)[par];
            }
        }

        const options = { headers: this.getHeadersForPost() };
        // not sure "" is a good default for itemJson.id
        const p: Promise<TrimItem> = this.instance.projectItemItemPut(body, project, itemJson.id || "", options);
        return p.then((result: TrimItem) => {
            // not sure "" is a good default for itemJson.id
            let item = this.parseItemJSON(itemJson.id || "", result as XRPutProject_EditItem_TrimItem);
            return item;
        });
    }

    public async updateItemInProject(project: string, item: IItemPut, currentVersion?: number): Promise<string> {
        // not sure "" is a good default
        return (await this.appUpdateItemInDBAsync(project, item, "edit", currentVersion)).id || "";
    }

    public async createItemInProject(project: string, parentFolderId: string, item: IItemPut): Promise<string> {
        const newId = await this.createItemFromIItemPut(project, parentFolderId, item);
        return newId;
    }

    /**
     * sets multiple fields in the database
     *
     * Use: await api.setFields("PROC-83", [{fieldName:"plain english",value:"x"}]  )
     *
     * @param itemId itemId itemId the id of the item like "REQ-1"
     * @param data array of fieldName and value tupels
     * @throws Error in case of invalid id or fields
     * @returns the updated item
     */
    public async setFields(itemId: string, data: ISetField[]): Promise<IItemGet> {
        return this.setFieldsInProject(this.getProject(), this.getItemConfig(), itemId, data);
    }

    public async setFieldsInProject(
        project: string,
        projectItemConfig: ItemConfiguration,
        itemId: string,
        data: ISetField[],
    ): Promise<IItemGet> {
        this.log(`set fields "${JSON.stringify(data)} of item "${itemId}" in project "${project}" `);
        let update: IItemPut = {
            id: itemId,
            onlyThoseFields: 1,
            onlyThoseLabels: 1,
        };
        let type = this.parseRefForProject(project, itemId).type;
        if (!type) {
            const msg: string = `This is not possibly an item in this project: "${itemId}"!`;
            this.logger.error(msg);
            throw new Error(msg);
        }
        for (let s of data) {
            let fieldId = projectItemConfig.getFieldId(type, s.fieldName);
            if (!fieldId) {
                const msg: string = `"${s.fieldName}" is not a field of this item "${itemId}"!`;
                this.logger.error(msg);
                throw new Error(msg);
            }
            update["fx" + fieldId] = s.value;
        }

        return this.appUpdateItemInDBAsync(this.getProject(), update, "edit");
    }

    public async addDownLink(fromId: string, toId: string): Promise<string> {
        this.log(`Add downlink from "${fromId} to "${toId}"`);

        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectItemlinkUpitemDownitemPost(
            this.getProject(),
            fromId,
            toId,
            this.getComment(),
            options,
        );
    }

    public async deleteItem(itemId: string, force?: boolean): Promise<string> {
        this.log(`Delete Item "${itemId}"`);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (force == undefined) {
            force = false;
        }
        return this.appDeleteItem(this.getProject(), itemId, force);
    }

    public async deleteItemInProject(project: string, itemId: string, force?: boolean): Promise<string> {
        this.log(`Delete Item "${itemId}" in project "${project}"`);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (force == undefined) {
            force = false;
        }
        return this.appDeleteItem(project, itemId, force);
    }

    private async appDeleteItem(project: string, itemId: string, force: boolean): Promise<string> {
        return this.appGetItemAsync(project, itemId).then((itemJson: IItem) => {
            const comment: string = this.getComment();
            let confirm: string = "no";
            if (itemJson.isFolder && itemJson.children && force) {
                confirm = "yes";
            }
            if (!force && itemJson.children && itemJson.children.length > 0) {
                throw new Error(`Item "${itemId}" not deleted because it has children`);
            }
            const options = { headers: this.getHeadersForPost() };
            return this.instance.projectItemItemDelete(project, itemId, confirm, comment, options);
        });
    }

    public async deleteDownLink(fromId: string, toId: string): Promise<string> {
        this.log(`Delete downlink from "${fromId} to "${toId}"`);
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectItemlinkUpitemDownitemDelete(
            this.getProject(),
            fromId,
            toId,
            this.getComment(),
            options,
        );
    }

    public async deleteDownLinks(fromId: string): Promise<string[]> {
        this.log(`Delete all downlinks from "${fromId}"`);

        let dls = await this.getDownlinkIds(fromId);
        let results: string[] = [];
        for (let dl of dls) {
            results.push(await this.deleteDownLink(fromId, dl));
        }
        return results;
    }

    public async deleteUpLinks(fromId: string): Promise<string[]> {
        this.log(`Delete all uplinks from "${fromId}"`);

        let uls = await this.getUplinkIds(fromId);
        let results: string[] = [];
        for (let ul of uls) {
            results.push(await this.deleteDownLink(ul, fromId));
        }
        return results;
    }

    /**
     * create a new item in the database
     *
     * Use: createItem( "F-REQ-1", "my item", [{fieldName:"description",value:"x"}], ["labelx"], downlinks:["SPEC-1"], uplinks:[] )
     *
     * @param folder where to store the item
     * @param title name of the item
     * @param data array with fieldNames and values
     * @param labels list of labels to set
     * @param downlinks list of downlinks to create
     * @param uplinks list of uplinks to create
     * @returns the created item id
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public createItem(
        folder: string,
        title: string,
        data?: ISetField[],
        labels?: [],
        downlinks?: [],
        uplinks?: [],
    ): Promise<string> {
        this.log(
            `Create item ${title} in folder ${folder} with labels: ${labels ? labels.join(",") : ""} downlinks: ${
                downlinks ? downlinks.join(",") : ""
            } and uplinks:${uplinks ? uplinks.join(",") : ""} `,
        );
        let that = this;
        let category = this.parseRef(folder).type;
        let update: IItemPut = { title: title, type: category };
        if (data) {
            for (let s of data) {
                let fieldId = this.getItemConfig().getFieldId(category, s.fieldName);
                if (!fieldId) {
                    const msg: string = `"${s.fieldName}" is not a field of this category "${category}"!`;
                    this.logger.error(msg);
                    throw new Error(msg);
                }
                update[fieldId] = s.value;
            }
        }
        if (labels && labels.length) {
            update.labels = labels.join(",");
        }

        const result: Promise<string> = that.appCreateItemOfTypeAsync(
            this.getProject(),
            category,
            update,
            "add",
            folder,
        );
        return result.then(async (newItemId: string) => {
            let itemId = that.parseRef(newItemId).id;
            if (downlinks) {
                for (let link of downlinks) {
                    await that.addDownLink(itemId, link);
                }
            }
            if (uplinks) {
                for (let link of uplinks) {
                    await that.addDownLink(link, itemId);
                }
            }
            return newItemId;
        });
    }

    public async createItemFromIItemPut(project: string, folder: string, item: IItemPut): Promise<string> {
        let that = this;

        let category = this.parseRef(folder).type;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (category != item.type) {
            const msg = `Folder category of ${category} does not match item category of ${item.type}`;
            this.logger.error(msg);
            throw new Error(msg);
        }

        const result: Promise<string> = this.appCreateItemOfTypeAsync(project, category, item, "add", folder);
        return result.then(async (newItemId: string) => {
            let itemId = that.parseRef(newItemId).id;
            // TODO: why can't this be done in the initial call? Seems like more trips to server than
            // required.
            if (item.downlinks) {
                for (let link of item.downlinks) {
                    await that.addDownLink(itemId, link);
                }
            }
            if (item.uplinks) {
                for (let link of item.uplinks) {
                    await that.addDownLink(link, itemId);
                }
            }
            return newItemId;
        });
    }

    // Returns a promise with the id of the created item.
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private async appCreateItemOfTypeAsync(
        project: string,
        category: string,
        itemJson: IItemPut,
        actions: string,
        parentId: string,
    ): Promise<string> {
        const comment = this.getComment();
        let body: ProjectItemBody = {
            reason: comment,
            title: itemJson.title || "",
            linksUp: itemJson.linksUp || "",
            linksDown: itemJson.linksDown || "",
            labels: itemJson.labels || "",
            folder: parentId,
            itemProperties: {},
        };
        if (itemJson.children) {
            let postItFolder: XCPostAddFolder = {
                label: itemJson.title || "",
                parent: parentId,
                reason: comment,
            };
            let fxFields = {};
            for (let par in itemJson) {
                if (!itemJson.hasOwnProperty(par)) {
                    continue;
                }
                if (postItFolder.hasOwnProperty(par)) {
                    continue;
                }
                if (
                    par === "type" ||
                    par === "children" ||
                    par === "title" ||
                    par === "labels" ||
                    par === "linksUp" ||
                    par === "linksDown"
                ) {
                    continue;
                }
                if (!isNaN(Number(par))) {
                    (<IGenericMap>fxFields)["fx" + par] = (<IGenericMap>itemJson)[par];
                }
            }
            const options = { query: fxFields, headers: this.getHeadersForPost() };
            let ack: Promise<AddItemAck> = this.instance.projectFolderPost(
                project,
                parentId,
                itemJson.title || "",
                comment,
                undefined,
                options,
            );
            return ack.then((result: AddItemAck) => {
                itemJson.id = "F-" + itemJson.type + "-" + result.serial;
                return itemJson.id;
            });
        }
        for (let par in itemJson) {
            if (!itemJson.hasOwnProperty(par)) {
                continue;
            }
            if (par === "type" || par === "labels" || par === "linksUp" || par === "linksDown") {
                continue;
            }
            if (!isNaN(Number(par))) {
                // it's a number so we assume it's a field
                (<IGenericMap>body).itemProperties["fx" + par] = (<IGenericMap>itemJson)[par];
            }
        }
        const options = { headers: this.getHeadersForPost() };
        let ack: Promise<AddItemAck> = this.instance.projectItemPost(body, project, options);
        return ack.then((result: AddItemAck) => {
            itemJson.id = itemJson.type + "-" + result.serial;
            return itemJson.id;
        });
    }

    /**
     * Creates a folder
     *
     * @param parent where to store the folder
     * @param title name of the folder
     * @param data array with fieldNames and values
     * @throws error in case of input error (bad fields, etc)
     * @returns Promise to the item id of folder
     */
    public async createFolder(parent: string, title: string, data?: ISetField[]): Promise<string> {
        this.log(`Create folder "${title} in folder "${parent}" `);
        let type = this.parseRef(parent).type;
        let update: IItemPut = { title: title, children: [], type: type };
        if (data) {
            for (let s of data) {
                let fieldId = this.getItemConfig().getFieldId("FOLDER", s.fieldName);
                if (!fieldId) {
                    const msg: string = `"${s.fieldName}" is not a field of a FOLDER"!`;
                    this.logger.error(msg);
                    throw new Error(msg);
                }
                update[fieldId] = s.value;
            }
        }
        // TODO: is XTC really correct here?
        return this.appCreateItemOfTypeAsync(this.getProject(), "XTC", update, "add", parent);
    }

    public async getItemIdByTitle(category: string, title: string): Promise<string | null> {
        this.log(`get item by title "${title}" in category "${category}"`);
        let that = this;
        let itemsPromise = this.search("mrql:category=" + category);
        return itemsPromise.then((items: ISearchResult[]) => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (!items || items.length == 0) {
                that.log(`Warning there's no item with title '${title}' in category '${category}'`);
                return null;
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            const itemsFilteredByName = items.filter((item) => item.title == title);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (itemsFilteredByName.length == 0) {
                that.log(`Warning there's no item with title '${title}' in category '${category}'`);
                return null;
            }
            if (itemsFilteredByName.length > 1) {
                that.log(
                    `Warning there's more than one match. Returning first item with title '${title}' in category '${category}'`,
                );
            }
            that.log(`get item by title "${title}" in category "${category}" => ${itemsFilteredByName[0].itemId}`);
            return itemsFilteredByName[0].itemId;
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async copyItem(
        fromProject: string,
        fromItem: string,
        toProject: string,
        toFolder: string,
        copyLabels: boolean,
    ): Promise<CopyItemAck> {
        this.log(`Copy Item "${fromProject}/${fromItem}" to  "${toProject}/${toFolder}"`);
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectCopyItemOrFolderPost(
            fromProject,
            fromItem,
            toFolder,
            this.getComment(),
            toProject,
            copyLabels ? 1 : 0,
            undefined,
            undefined,
            options,
        );
    }

    /**
     * Get the TODOs for a project.
     * @param project project name
     * @param itemRef if specified, returns all todos linked to an item, regardless of user
     * @param includeDone - if true, includes done todos
     * @param includeAllUsers - if true, includes all todos for all users.
     * @param includeFuture - false by default. If true, includes future todos.
     * @returns Information on the todos.
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async getProjectTodos(
        project: string,
        itemRef?: string,
        includeDone?: boolean,
        includeAllUsers?: boolean,
        includeFuture?: boolean,
    ): Promise<GetTodosAck> {
        return this.instance.projectTodoGet(
            project,
            itemRef,
            includeDone ? 1 : 0,
            includeAllUsers ? 1 : 0,
            includeFuture ? 1 : 0,
        );
    }

    public async postProjectReport(project: string, item: string, format: string): Promise<CreateReportJobAck> {
        //rest url and baseUrl should be provided.
        let baseUrl = this.matrixBaseUrl;
        let restUrl = this.baseRestUrl;
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectReportReportPost(
            project,
            item,
            "false",
            "false",
            item,
            "none",
            undefined,
            baseUrl,
            restUrl,
            format,
            undefined,
            undefined,
            undefined,
            undefined,
            options,
        );
    }

    public async getJobStatus(project: string, jobId: number, options?: unknown): Promise<JobsStatusWithUrl> {
        return this.instance.projectJobJobGet(project, jobId, options);
    }

    /**
     * Actually download a job file
     * @param project
     * @param jobId
     * @param fileno
     * @param mode
     * @param format
     * @param disposition
     * @param options
     * @returns An ArrayBuffer
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async downloadJobResult(
        project: string,
        jobId: number,
        fileno: number,
        mode?: string,
        format?: string,
        disposition?: string,
        options?: unknown,
    ): Promise<ArrayBuffer> {
        return this.instance.projectJobJobFilenoGet(project, jobId, fileno, mode, format, disposition, options);
    }

    public async postJobProgressForProject(
        project: string,
        jobId: number,
        progress: number,
        status?: string,
    ): Promise<string> {
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectJobJobPost(project, jobId, progress, status, options);
    }

    public async deleteJobForProject(project: string, jobId: number, reason: string): Promise<string> {
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectJobJobDelete(project, jobId, reason, options);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    createTodo(
        project: string,
        users: string[],
        type: TodoTypes,
        text: string,
        itemId: string,
        fieldId: number | null,
        atDate: Date,
    ): Promise<string> {
        const options = { headers: this.getHeadersForPost() };
        return this.instance.projectTodoItemPost(
            project,
            itemId,
            text,
            fieldId || undefined,
            users.join(","),
            type,
            atDate.toISOString(),
            undefined,
            options,
        );
    }

    /**
     * Retrieve all changes in a project
     * @param project - project name
     * @param startAt - (optional) start the audit after N records
     * @param maxResults - (optional) retrieve N results per page
     * @param deleteOnly - (optional) if true, only returns actions of type delete
     * @param tech - (optional) if true, returns the underneath changes
     * @param auditIdMin - (optional) sets a minimum ID for audits as returned by GET calendar
     * @param auditIdMax - (optional) sets a maximum ID for audits
     * @param noReport - (optional) if true, avoid reports in the output
     * @param noImport - (optional) if true, avoid imports in the output
     * @param include - (optional) a comma-seperated list of actions to include (delete,undelete,add,edit,...)
     * @param resolveRef - (optional) if true, resolve item IDs into refs
     * @param itemRef - (optional) restrict the audit to only those mentioning this item
     * @returns a TrimAuditList structure
     */
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async getProjectAudit(
        project: string,
        startAt?: number,
        maxResults?: number,
        deleteOnly?: boolean,
        tech?: boolean,
        auditIdMin?: number,
        auditIdMax?: number,
        noReport?: boolean,
        noImport?: boolean,
        include?: string,
        resolveRef?: boolean,
        itemRef?: string,
    ): Promise<TrimAuditList> {
        return this.instance.projectAuditGet(
            project,
            startAt,
            maxResults,
            deleteOnly ? "yes" : "no",
            tech ? "yes" : "no",
            auditIdMin,
            auditIdMax,
            noReport ? 1 : 0,
            noImport ? 1 : 0,
            include,
            resolveRef ? 1 : 0,
            itemRef,
        );
    }

    // using unknown to make compiler happy. actually `rootGet` accepts any
    public async rootGet(adminUI?: number, output?: string, options?: unknown): Promise<ListProjectAndSettings> {
        return this.instance.rootGet(adminUI, output, options);
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    public async validateSdkVersion() {
        const settings = await this.rootGet();

        const serverVersion = settings.serverVersion || "";
        const sdkSettings = settings.customerSettings?.find((setting) => setting.key === SDK_SETTINGS_KEY);
        const settingsMinVersion = JSON.parse(sdkSettings?.value ?? "{}")?.minVersion;

        return validateSdkVersion({
            sdkVersion: SDK_VERSION,
            serverVersion,
            settingsMinVersion,
        });
    }
}
