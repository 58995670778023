import { ITitleAndId } from "../standalone-interfaces";
import { Item } from "./Item";
import { ITreeFolderNeeds } from "./object-interfaces";
import { FancyFolder } from "../rest-api";

export { TreeFolder };

/**
 * A TreeFolder represents a folder in the Matrix application. It can answer queries about
 * folder and item children. A Folder is also an Item.
 */
class TreeFolder {
    private id: string;
    private title: string;
    private type: string;

    private folderChildren: TreeFolder[];
    private itemChildren: ITitleAndId[];

    constructor(
        private needs: ITreeFolderNeeds,
        folder: FancyFolder,
        private parent?: TreeFolder,
    ) {
        // Decompose folder.
        this.id = folder.id || "";
        this.title = folder.title || "";
        this.type = folder.type || "";
        this.itemChildren = [];
        this.folderChildren = [];

        if (folder.children) {
            // Some of the children are folders, others items.
            for (let child of folder.children) {
                if (needs.parseRef(child.id || "").isFolder) {
                    // Recurse and create TreeFolders.
                    this.folderChildren.push(new TreeFolder(needs, child, this));
                } else {
                    this.itemChildren.push({ id: child.id || "", title: child.title || "", isFolder: false });
                }
            }
        }
    }

    isRoot(): boolean {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        return this.id === "" && this.parent == undefined;
    }

    getId(): string {
        if (this.isRoot()) {
            return "ROOT";
        }
        return this.id;
    }
    getTitle(): string {
        if (this.isRoot()) {
            return "ROOT";
        }
        return this.title;
    }

    getParent(): TreeFolder | null {
        if (!this.parent || this.isRoot()) {
            return null;
        }

        return this.parent;
    }

    /**
     * Creates a path string including all ancestor folder titles, separated by "/".
     * @returns the folder path
     */
    getPath(): string {
        const parent = this.getParent();

        if (!parent) {
            return "";
        }

        const result = parent.getPath() + "/" + this.getTitle();
        return result;
    }

    /**
     * Return a TreeFolder if the folderId is valid in the project.
     * @param folderId
     * @returns null if folderId cannot be found.
     */
    findFolder(folderId: string): TreeFolder | null {
        // Is it one of the children?
        for (let child of this.folderChildren) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (child.getId() == folderId) {
                return child;
            }
            const folder = child.findFolder(folderId);
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (folder != null) {
                return folder;
            }
        }
        return null;
    }

    /**
     * Find a TreeFolder with the given name in this folder.
     * @param folderTitle
     * @returns A valid TreeFolder object or null if not found.
     */
    findDirectFolderByTitle(folderTitle: string): TreeFolder | null {
        for (let child of this.folderChildren) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (child.getTitle() == folderTitle) {
                return child;
            }
        }
        return null;
    }

    /**
     * Save an item with this folder as the parent folder. If the item already exists it will be moved there.
     * @param item An item
     * @returns An Item object which corresponds to the newly created or moved Item on the server.
     */
    async saveInFolder(item: Item): Promise<Item> {
        let createdItem = await this.needs.putItem(this.id, item);

        // Update the folder structure just for this folder.
        if (!createdItem.isFolder()) {
            this.itemChildren.push({ isFolder: false, id: createdItem.getId(), title: createdItem.getTitle() });
        } else {
            // New folders don't have children, so we can get away with adding this folder without a
            // server call.
            const folderInfo: FancyFolder = {
                id: createdItem.getId(),
                title: createdItem.getTitle(),
                type: createdItem.getType(),
            };
            this.folderChildren.push(new TreeFolder(this.needs, folderInfo, this));
        }
        return createdItem;
    }

    /**
     * Move the given items into this folder. This method does NOT update the list of folder children,
     * since server-side information is necessary.
     * @param itemIds an array of itemIds
     * @returns the string "Ok" on success
     */
    async moveItemsToThisFolder(itemIds: string[]): Promise<string> {
        const result = await this.needs.moveItems(this.getId(), itemIds);
        return result;
    }

    /**
     * Delete a child of this folder.
     * @param id A valid child id of this folder
     * @param force If the id points to a non-empty folder, then this must be true to carry out the deletion
     * @throws Error if the child wasn't found, or if it points to a non-empty folder and {force} is not true
     * @returns The string "Ok" if successful.
     */
    async deleteChildItemOrFolder(id: string, force?: boolean): Promise<string> {
        // The id must be one of our children.
        for (let i = 0; i < this.itemChildren.length; i++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.itemChildren[i].id == id) {
                let result = await this.needs.deleteItem(id, force);
                this.itemChildren.splice(i, 1);
                return result;
            }
        }
        for (let i = 0; i < this.folderChildren.length; i++) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (this.folderChildren[i].getId() == id) {
                let result = await this.needs.deleteItem(id, force);
                this.folderChildren.splice(i, 1);
                return result;
            }
        }
        // Item isn't a child if we reached this point.
        throw new Error(`Item with id ${id} not found in item or folder children`);
    }

    /**
     * TreeFolder is nice/simple to work with, but sometimes you need the
     * underlying Item. For example, you might want to set a label on the folder.
     * Item is the necessary type to do that.
     * @returns An Item which matches this folder
     */
    async getItem(): Promise<Item> {
        return this.needs.getItem(this.id);
    }

    /**
     * Returns information on the folders in the folder. May make a request to
     * the server if children haven't been loaded yet, otherwise, acts on
     * cached information (which may be out of date. Call refresh() to update
     * the folder in that case).
     * @returns an array of TreeFolder objects.
     */
    getFolderChildren(): TreeFolder[] {
        return this.folderChildren;
    }

    /**
     * Returns information on the items in the folder. May make a request to
     * the server if children haven't been loaded yet, otherwise, acts on
     * cached information (which may be out of date. Call refresh() to update
     * the folder in that case).
     * @returns an array of ITitleAndId objects
     */
    getItemChildren(): ITitleAndId[] {
        return this.itemChildren;
    }

    /**
     * Returns both folders and items at this level.
     * @returns an array of ITitleAndId objects for the folders and items
     */
    getAllChildren(): ITitleAndId[] {
        let children: ITitleAndId[] = [];
        children = children.concat(this.getItemChildren());
        children = children.concat(
            this.getFolderChildren().map((tf) => {
                return { isFolder: true, id: tf.id, title: tf.title };
            }),
        );
        return children;
    }
}
